import React, { useState } from "react";
import { ObjectInspector } from "react-inspector";
import { chromeLight } from 'react-inspector'

import { AiOutlineUnorderedList } from "react-icons/ai";
import { BsFillDiagram2Fill } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { ObjectInspectorGrid } from "./ObjectInspectorGrid";
import { ObjectInspectorGraph } from "./ObjectInspectorGraph";


export function JournalExplorer(data) {
  const [view, setView] = useState(1);

  function changeView(icon){
    setView(icon);
  }

  let theme={...chromeLight, ...(
    { 
        BASE_BACKGROUND_COLOR: 'var(--fondo-color)',
        BASE_COLOR: 'var(--debug-import-doc)',
        OBJECT_NAME_COLOR: 'var(--debug-public-class)',
        OBJECT_VALUE_NULL_COLOR: 'var(--debug-import-doc)',
        OBJECT_VALUE_NULL_COLOR: 'var(--debug-import-text)',
        TREENODE_PADDING_LEFT: 15,
        OBJECT_VALUE_STRING_COLOR: 'var(--debug-background-color)',
        ARROW_COLOR: 'var(--debug-background-color)',
        TREENODE_FONT_FAMILY: 'DejaVu Sans Mono',
        TREENODE_FONT_SIZE: '15px'
    }
)}

  
  if(data.journal && data.journal.transactionId){
    const cloneFood = { ...data.journal };
    delete cloneFood.diagram;
    delete cloneFood.id;
    delete cloneFood.accountId;
    delete cloneFood.userId;
    
    return (<>
      <div className="astMenu">
        <AiOutlineUnorderedList className= {view ===1 ?"astIcon astIconSelected": "astIcon"} onClick={() =>changeView(1)}/>
        <BsFillDiagram2Fill className= {view ===2 ?"astIcon astIconSelected": "astIcon"} onClick={() =>changeView(2)}/>
        <VscGraphLine className= {view ===2 ?"astIcon astIconSelected": "astIcon"} onClick={() =>changeView(3)}/>
      </div>

      {
        (view === 1
          ?<ObjectInspector theme={theme} expandLevel="1" data={cloneFood} />
          :(view === 2
            ?<ObjectInspectorGrid data={cloneFood}></ObjectInspectorGrid>
            :<ObjectInspectorGraph data={cloneFood}></ObjectInspectorGraph>
           )
        )
      }
      
      </>);
  }else{
    return (<></>);
  }
}
