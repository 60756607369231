import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function InicioRapido(data){
  let ident = "\u00A0\u00A0";
  let end = ";";
  let llaveInicio = "{";
  let llaveTermino = "}";

    return (
        <>
          <div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">
                    <h1 id="inicio">Inicio rápido</h1>
                    <p>Bienvenido al inicio rápido de TrackIA, está sección 
                    le brinda una breve introducción a los conceptos básicos 
                    para una puesta en marcha sencilla.
                    </p>

                    <h2>Maven</h2>
                    <p>
                    Para importar TrackIA en su proyecto, debe agregar la librería a través 
                    la dependencia Maven.
                    </p>

                    <p>
                      Nota: En versiones tempranas, es necesario bajar el jar directamente desde <a href="/documentation/install">download</a>.
                      Trabajamos arduamente para mejorar este mecanismo.
                    </p>
                    <div className="pre">
                    <p></p>
                    <p>{'<'}<a className="valueType">dependency</a>{'>'}</p>
                    <p>{ident+'<'}<a className="valueType">groupId</a>{'>'}<a className="descriptionType ">app.trackia</a>{'</'}<a className="valueType">groupId</a>{'>'}</p>
                    <p>{ident+'<'}<a className="valueType">artifactId</a>{'>'}<a className="descriptionType ">trackia</a>{'</'}<a className="valueType">artifactId</a>{'>'}</p>
                    <p>{ident+'<'}<a className="valueType">version</a>{'>'}<a className="descriptionType ">1.1.6</a>{'</'}<a className="valueType">version</a>{'>'}</p>
                    <p>{'</'}<a className="valueType">dependency</a>{'>'}</p>
                    </div>
                    

                    <h2>Enviando la salida a Trackia Cloud</h2>
                    <p>
                      Este paso es opcional, te permite disfrutar de todas las vistas y herramientas online que tenemos para ti. 
                      Si prefieres, puedes saltar a <a href="#logOutput">Enviando la salida al Log</a></p>
                    <p>
                      <li><b>Paso 1</b>: <a href="/account/create">Crear una cuenta</a>, si ya posee una omita este paso.</li>
                      <li><b>Paso 2</b>: Cree un <a href="/documentation/artifacts">artefacto</a>
                      </li>
                    </p>

                    <h2>application.yml</h2>

                    <div className="pre">
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.url: </a><a className="descriptionType ">https://trackiaapp.com/journal</a></p>
                    <p><a className="valueType">trackia.dispatcher.auth: </a><a className="descriptionType ">https://trackiaapp.com/auth/signin</a></p>
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.account: </a><a className="descriptionType ">:accountId</a></p>
                    <p><a className="valueType">trackia.dispatcher.apiKey: </a><a className="descriptionType ">:apikey</a></p>
                    <br/>
                    </div>



                    <h2 id="logOutput">Enviando la salida al Log</h2>

                    <p>Puede preferir solo enviar la salida al log configurado.</p>
                    <h2>application.yml</h2>

                    <div className="pre">
                      <br/>
                      <p><a className="valueType">trackia.dispatcher.class: </a><a className="descriptionType ">trackiaLoggerDispatcher</a></p>
                    </div>



                    <h2>Java</h2>
                    
                    <h3>Controller:</h3>

                    <p>
                      En el controler se debe agregar la anotación @Trackia con la propiedad <b>write</b> = <b>true</b>, este atributo 
                      indica que una vez terminado el método, se debe enviar toda la información recolectada al dispatcher configurado.
                    </p>

                    <div className="pre">
                      <br/>
                      <p><a className="valueAnotation">@Trackia</a>(
                      <a className="descriptionType">write </a> = <a className="valueType">true</a>
                      )</p>

                      
                      <p><a className="valueAnotation">@GetMapping </a>(<a className="descriptionType">"helloWorld"</a>)</p>
                      <p>
                        <a className="import-text">public</a> 
                        <a className=""> ResponseEntity{'<String> '}</a>
                        <a className="import-text">helloWorld</a>
                        <a className="valueAnotation">(@RequestBody</a>
                        <a className=""> HelloWorldRequest </a>
                        <a className="varExp">request</a>
                        <a>) {llaveInicio}</a>
                      </p>

                      <p>
                          <a className="import-text">{ident+ident+ 'return new '}</a>
                          <a className="">{'ResponseEntity<>(helloWorldService.helloWorld'}</a>
                          <a className="valueType">{'(request), '}</a>
                          <a className="import-text">{'HttpStatus.CREATED);'}</a>
                      </p>
                      <p>{llaveTermino}</p>
                      </div>


                    <h3>Capas Internas</h3>
                    <p>
                    Una capa interna es un sub-proceso llamado desde el Controller,
                    generalmente un @Service o @Component.

                    Como buena práctica se recomienda aplicar a métodos de 
                    integración que sean sensibles a fallos externos.
                    </p>

                    <div className="pre">
                    <br/>

                    <p>
                      <a className="valueAnotation">@Trackia</a>
                    </p>
                  
                    <p>
                        <a className="import-text">public</a>
                        <a> String </a> 
                        <a className="import-text">helloWorld</a>
                        <a className="valueAnotation">(</a>
                        <a className="">HelloWorldRequest </a>
                        <a className="varExp">request</a>
                        <a>) {llaveInicio}</a>
                    </p>

                    <p>{ident}<a className="import-text">return</a> <a className="descriptionType">"Hello World"</a>;</p>
                    <p>{llaveTermino}</p>
                    </div>






                    <h3>ComponentScan</h3>
                    <p>
                    Configura la directiva de escaneo de componentes en la clase principal para que springboot cargue el beans.
                    </p>

                    <div className="pre">
                    <br/>

                    <p>
                      <a className="valueAnotation">@ComponentScan(</a>
                      <a className="valueType">"trackia.app"</a>)
                    </p>
                    </div>

                </div>
            </div>
        </div>
        </div>
     </>);
}