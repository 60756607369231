import React, { useState } from "react";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const chartSetting = {
    yAxis: [
      {
        label: 'rainfall (mm)',
      },
    ],
    width: 500,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  };



  
const valueFormatter = (value) => `${value}ms duration`;

export function ObjectInspectorGraph(data) {
    function createData(dataset, step){
        let childrenDuration = 0;
        for(var i=0;i<step.children.length; i++){
            createData(dataset, step.children[i]);
            childrenDuration = childrenDuration + step.children[i].duration;
        }
        dataset.push(step.duration - childrenDuration);
        return dataset;
    }

    function createNames(dataset, step){
        for(var i=0;i<step.children.length; i++){
            createNames(dataset, step.children[i]);
        }

        if(step.method.startsWith("HTTP")){
            dataset.push("Step" + dataset.length + ":" + step.url);
        }else{
            dataset.push("Step" + dataset.length + ":" + step.method);
        }

        
        return dataset;
    }

    let dataset = [];
    let dataNames = [];
    
    createData(dataset, data.data);
    createNames(dataNames, data.data);
    return(
        <BarChart xAxis={[{data: dataNames, scaleType: 'band'}]} series={[{data: dataset, valueFormatter}]}/>
    );
    
}
