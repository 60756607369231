import * as React from 'react';
import plantumlEncoder from 'plantuml-encoder';


export function ReactPlantUML({alt, diagram}){
    if(diagram){
        const encode = plantumlEncoder.encode(diagram);
        const url = `http://www.plantuml.com/plantuml/svg/${encode}`;
        
        return <><img alt={alt} src={url} ></img></>;
    }else{
        return (<><p></p></>);
    }
};
