import React, { useState } from 'react';
import axios from 'axios';
import {useNodesState, useEdgesState, MarkerType, addEdge } from 'reactflow';

import { HeaderZone } from '../HeaderZone';
import { JElement } from './JElement';
import { ComponentDiagram } from './ComponentDiagram';
import { CodeView } from './CodeView';
import { ReactPlantUML } from './ReactPlantUML';
import { JournalExplorer } from './JournalExplorer';

import { BsDiagram3, BsSearch} from "react-icons/bs";
import { BiSearch, BiMenu } from "react-icons/bi";
import { SiDiagramsdotnet } from "react-icons/si";
import { MdOutlineConstruction } from 'react-icons/md';
import { VscSymbolStructure } from "react-icons/vsc";
import { GiTreeDoor } from "react-icons/gi";
import '../theme/Dark.css';
import './index.css';
import { AiOutlineClose } from 'react-icons/ai';
import jwt_decode from "jwt-decode";

import Service from '../../helpers/Service';
import { Search } from './Search';

function createInitialNodes (patherId, xPadre, nodes, journal){
  if(journal && journal.transactionId){
    if(journal.rootElement){
      nodes.push(
        {
          id: journal.transactionId + journal.part, 
          key: journal.transactionId + journal.part,
          sourcePosition: 'right',
          targetPosition: 'left',
          position: { x: xPadre , y: 120 + ((nodes.length+1) * 65)  }, 
          style: {
            "background": "var(--debug-background-color)",
            "color": "white",
            "fontFamily": "DejaVu Sans Mono",
            "fontSize": "10px",
            "fontWeight": "bold",
            "boxShadow": "rgba(0, 0, 0, 1) 0px 19px 38px, var(--main-background-color) 0px 7px 12px",
            "border": "1px solid #222138",
            "width": "180px",
            "height": "50px",
            "overflow": "hidden",
            "white-space": "pre",
          },
          journal: journal,
          data: {
            label: (
            <>
              <div 
                className={journal.status + ` status2`}>
                  {journal.status}
              </div>
              <div className='component-element-box'>
                <p className="component-element">
                  {journal.artifact}
                </p>
                <p className="url-element">
                  {journal.url}
                </p>

                <p className="description-element">
                  {journal.description}
                </p>

                <p className="duration-element">
                  {journal.duration}ms
                </p>
              </div>

            </> 
            )
          },
          patherId: patherId
        }
      );

      xPadre = xPadre + 250;
      patherId = nodes[nodes.length-1].id;
    }

    for(let i=0; i<journal.children.length; i++){
      createInitialNodes(patherId, xPadre, nodes, journal.children[i]);
    }
  }

  return nodes;
}

function createInitialEdges (initialNodes){
  let InitialEdges = [];

  for(let i=1; i<initialNodes.length; i++){
    InitialEdges.push({ source: initialNodes[i].patherId, key:"Edges" + initialNodes[i].id, id:"Edges" + initialNodes[i].id, target: initialNodes[i].id, markerEnd: {type: MarkerType.ArrowClosed}, animated: true });
  }

  return InitialEdges;
}


export function E2E(ctx){
  let context = ctx;
  
  function displayArtifactDiagram(){
    setMenuIcon({"display": "none"});
    setComponentDiagram({"display": "block"});
    setSecuenceDiagram({"display": "none"});
    setJournalExplorer({"display": "none"});
  }

  function displaySecuenceDiagram(){
    setMenuIcon({"display": "none"});
    setComponentDiagram({"display": "none"});
    setSecuenceDiagram({"display": "block"});
    setJournalExplorer({"display": "none"});
  }

  function displayJournalExplorer(){
    setMenuIcon({"display": "none"});
    setComponentDiagram({"display": "none"});
    setSecuenceDiagram({"display": "none"});
    setJournalExplorer({"display": "block"});
  }

  function findJournal (transactionId) {
    for(let i=0; i<model.length; i++){
      if(model && model[i] && model[i].transactionId === transactionId){
        return model[i];
      }
    }

    return null;
  }

  function findJournalInModel (transactionId, model) {
    if(model && model.length){
      for(let i=0; i<model.length; i++){
        if(model && model[i] && model[i].transactionId === transactionId){
          return model[i];
        }
      }
    }

    return {};
  }

  function findId (transactionId) {
    for(let i=0; i<model.length; i++){
      if(model && model[i] && model[i].transactionId === transactionId){
        return i;
      }
    }

    return null;
  }

  function selecciona (transactionId) {
    let journal = findJournal(transactionId);
    if(journal){
      setDiagram(journal.diagram.replaceAll(";", "\n"));

      const initialNodes = createInitialNodes("", 60, new Array(), journal);
      const initialEdges = createInitialEdges(initialNodes);
      window.localStorage.setItem(user.id + "_selected", journal.transactionId);

      setNodes(initialNodes);
      setEdges(initialEdges);

      setJournal(journal);
      setDebug(journal);
      setObjectExplorer({});
      if(!window.matchMedia("(min-width: 1024px)").matches){
        setJournalMenuTransaccion({"display": "none"});
      }
      setMenuIcon({"display": "none"});
    }
  }

  function onEditCode (e, obj){
    if(obj && obj.journal){
      setDebug(obj.journal);
      setObjectExplorer({});
    }
  }

  function displayTools(event){
    setDurationBar({"display": durationBar.display == "none" ? "block":"none"});
  }

  function onObjectExplorer (obj){
    if(obj){
      setObjectExplorer(obj);
    }
  }

  function disassociationAll (lista){
    for(let i=0; i<model.length; i++){
      lista[i] = disassociation(lista[i]);
    }
    return lista;
  }

  function disassociation (journal){
    delete journal.parent;
    for(let i=0; i<journal.children.length; i++){
      journal.children[i] = disassociation(journal.children[i]);
    }

    return journal;
  }


  async function elimina (transactionId, context){
    let id = findId(transactionId);
    if(id > -1){
      let newModel = model;
      newModel.splice(id,1);
      newModel = disassociationAll(newModel);
      setModel(newModel);   

      setDiagram("");
      setJournal({});
      setNodes([]);
      setEdges([]);

      if(!context.data.doc){
        window.localStorage.setItem(user.id + "_model", JSON.stringify(newModel));
      }
    }

    return null;
  }

  async function addJournal (event, context){
    if (event.key === 'Enter') {
      let journalInput = document.getElementById('journalInput').value;

      let old = findJournal(journalInput);
      
      if(!old){
        let response =  !context.doc 
          ?await srv.findByTransactionId(journalInput) : 
           await srv.findExampleByTransactionId(journalInput);
        
        if(response != undefined && response != null){
          let obj = response.data;

          let newModel = model;
          newModel = disassociationAll(newModel);

          newModel[newModel.length] = obj;

          if(!context.doc ){
            window.localStorage.setItem(user.id + "_model", JSON.stringify(newModel));
            window.localStorage.setItem(user.id + "_selected", obj.transactionId);
          }
          
          setModel(oldMolel => newModel);
          setDiagram(obj.diagram.replaceAll(";", "\n"));
          setJournal(j => (obj));
          setNodes([]);
          setEdges([]);

          document.getElementById('journalInput').value = "";
        }
      }else{
        selecciona(journalInput);
        document.getElementById('journalInput').value = "";
      }
    }
  }


  function loadModel(user, context){
    let localModel;
    if(!context.doc){
      localModel = localStorage.getItem(user.id + "_model");
      if(!localModel){
        localModel = [];
        window.localStorage.setItem(user.id + "_model", JSON.stringify(localModel));
      }else{
        try{
          localModel = JSON.parse(localModel);
        }catch(e){
          localModel = [];
        }
      }
    }else{
      localModel = [];
    }
    return localModel;
  }

  function getSelected(actualModel){
    let selected = localStorage.getItem(user.id + "_selected");
    if(!selected && actualModel){
      selected = actualModel[0];
    }

    if(!selected || !actualModel || actualModel.length == 0){
      selected = {};
    }

    return selected;
  }

  function getDiagram(actual){
    return actual && actual.diagram ? actual.diagram.replaceAll(";", "\n") : "";
  }

  let srv = new Service();
  let token = srv.getToken();
  let actualModel = [];
  let actual = {};
  let user = {}
  

  if(token){
    user = srv.getUser();

    actualModel = loadModel(user, context);

    let transactionId = getSelected(actualModel);
    actual = findJournalInModel(transactionId, actualModel);
  }

  const [sizeCodeView, setSizeCodeView] = useState({"display": "none"});
  const [menuIcon, setMenuIcon] = useState({"display": "none"});
  const [journalMenuTransaccion, setJournalMenuTransaccion] = useState({"display": !window.matchMedia("(min-width: 1024px)").matches ? "none" :"block"});
  
  
  const [reactFlowDiagramStyle, setReactFlowDiagramStyle] = useState({"width": "100%"});

  
  const [componentDiagram, setComponentDiagram] = useState({"display": "block"});
  const [secuenceDiagram, setSecuenceDiagram] = useState({"display": "none"});
  const [journalExplorer, setJournalExplorer] = useState({"display": "none"});
  const [durationBar, setDurationBar] = useState({"display": "none"});

  const [model, setModel] = useState(actualModel);

  const initialNodes = createInitialNodes("", 60, new Array(), actual);
  const initialEdges = createInitialEdges(initialNodes);
  
  const [journal, setJournal] = useState(actual);
  const [diagram, setDiagram] = useState(getDiagram(actual));
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const [debug, setDebug] = useState(actual);
  const [objectExplorer, setObjectExplorer] = useState({});

    return (
    <>
    
      <div className="">
        {!context.doc
          ?<HeaderZone iniciar={true}/>
          :<></>
        }

        <div className="dashbord">

          <div className='vistaMenu' onClick={() => {setJournalMenuTransaccion({"display": !window.matchMedia("(min-width: 1024px)").matches ?"none": "block"}); setMenuIcon({"display": menuIcon.display === "none" ?"block" :"none"});}}>
              {
                menuIcon.display === "none"
                  ?<BiMenu size={22}  />
                  :<AiOutlineClose size={22} />
              }
          </div>

          <div className='searchIcon' onClick={() => {setMenuIcon({"display": "none"}); setJournalMenuTransaccion({"display": journalMenuTransaccion.display === "none" ?"block" :"none"});}}>
              {
                journalMenuTransaccion.display === "none"
                  ?<BiSearch size={22}  />
                  :<AiOutlineClose size={22} />
              }
              
          </div>

          <div className="menuOpciones" style={menuIcon}>
            <ul> 
                <li aria-expanded="false" onClick={() => displayArtifactDiagram()}><span><BsDiagram3 size={14} style={{"color": "white"}}/> <a style={{"backgroundColor": componentDiagram.display ==="block" ? "var(--debug-background-color)" :"" }} href="#"> Artifact Diagram</a></span></li> 
                <li aria-expanded="false" onClick={() => displaySecuenceDiagram()}><span><VscSymbolStructure size={14} style={{"color": "white"}}/> <a style={{"backgroundColor": secuenceDiagram.display ==="block" ? "var(--debug-background-color)" :"" }} href="#"> Secuence Diagram</a></span></li> 
                <li aria-expanded="false" onClick={() => displayJournalExplorer()}><span><GiTreeDoor size={14} style={{"color": "white"}}/> <a style={{"backgroundColor": journalExplorer.display ==="block" ? "var(--debug-background-color)" :"" }} href="#"> AST Struct</a></span></li> 
                <li aria-expanded="false" onClick={() => {setMenuIcon({"display": "none"}); setSizeCodeView({"width": "100%"});}}><span><MdOutlineConstruction size={15} style={{"color": "white"}}/><a  href="#"> PSeudo Code</a></span></li> 

                
              </ul>
          </div>

          <div className="menuTransacciones " 
              style={journalMenuTransaccion}>
            <Search 
              journalMenuTransaccion={journalMenuTransaccion} 
              addJournal={addJournal} 
              model={model}
              journal={journal}
              selecciona={selecciona}
              elimina={elimina}
              data={context}
            >
            </Search>
          </div>

          <div className="journalPanel" style={componentDiagram} onClick={() =>setMenuIcon({"display": "none"})}>
                <div>
                  <ComponentDiagram 
                    journal={debug} 
                    nodes={nodes} 
                    edges={edges} 
                    onEditCode={onEditCode}
                    OnObjectExplorer={onObjectExplorer}
                    click={() =>setMenuIcon({"display": "none"})}
                    objectExplorer={objectExplorer}
                    reactFlowDiagramStyle={reactFlowDiagramStyle}
                    setSizeDiagram={setSizeCodeView}
                    />
                </div>
          </div>

          <div className="journalPanel relativePanel" style={secuenceDiagram} onClick={() =>setMenuIcon({"display": "none"})}>
            <ReactPlantUML alt={journal.description} diagram={diagram} />
          </div>

          <div className="journalPanel relativePanel" style={journalExplorer} onClick={() =>setMenuIcon({"display": "none"})}>
            <JournalExplorer journal={journal} />
          </div>
          

          <div className="codeView" style={sizeCodeView}>
            <CodeView journal={debug} 
                      onEditCode={onEditCode} 
                      setSizeDiagram={setSizeCodeView}
                      objectExplorer={objectExplorer}
                      OnObjectExplorer={onObjectExplorer} 
                      sizeCodeView={sizeCodeView}
                      displayTools={displayTools}
                      durationBar={durationBar}
                      maximizeView={() => {setSizeCodeView({"width": "0%"}); setMenuIcon({"display": "none"});}}
            />
          </div>
        </div>
     </div>
    </>
  );
}