import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import { Setting } from './Account/Setting';

export function HeaderZone(data){
    let token = localStorage.getItem('authorization');
    const [display, setDisplay] = useState({"display": "none"});
    
    let iniciarHtml;
    if(data && data.iniciar){
      iniciarHtml = <span><a className='iniciar hover radius-1cqb' href='/signin'> Sign In</a></span>;

      if(token && token !== null && token !== 'null'){
        var decoded = jwt_decode(token);
        
        if(decoded && decoded.user){
          let user = decoded.user;
          iniciarHtml = 
            <div onClick={() => setting(user, data)} className='sesionSetting radius-8cqb select-none hover'>
              <p>{user.firstName.substring(0,1)}</p>
            </div>;
        }
      }
    }

    const setting = (user, data) => {
      if(!data.doc){
        setDisplay({"display": "block"});
      }else{
        onSelect("/user", data);
      }
    }

    
    function onSelect (obj, data){
      if(data.doc){
        data.onSelect(obj);
      }
    }

    return (
      <>
        <div className="encabezadoE2E backgroundcolor ">
          <div>
            <div className="logo text-focus-in"><img src="../../img/trackia.jpeg" ></img></div>
          </div>
          <div className="sesion">
            {iniciarHtml}
          </div>

          <div id="menu-superior" className="menu-superior">
            <ul> 
              <li aria-expanded="false"><span><a className="all-menu"  onClick={() => {onSelect("/", data)}} href={!data.doc ?"/":"#"}>Home</a></span></li> 
              <li aria-expanded="false"><span><a className="all-menu"  onClick={() => {onSelect("/documentation", data)}} href={!data.doc ?"/documentation":"#documentation"}>Documentation</a></span></li> 
              <li aria-expanded="false"><span><a className="all-menu"  onClick={() => {onSelect("/E2E", data)}} href={!data.doc ?"/E2E":"#E2E"}>E2E</a></span></li> 
              <li aria-expanded="false"><span><a className="all-menu"  onClick={() => {onSelect("/summary", data)}} href={!data.doc ?"/summary":"#summary"}>Summary</a></span></li> 
              <li aria-expanded="false"><span><a className="desk-menu" onClick={() => {onSelect("/documentation/install", data)}} href={!data.doc ?"/documentation/install":"#documentation/install"}>Download</a></span></li> 
            </ul>
          </div> 
        </div>

        
        <Setting 
          settingStyle={display}
          setDisplay={setDisplay}
          doc={data.doc}
        ></Setting>
        
      </>
    )
}