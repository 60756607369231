import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';
import Service from '../../helpers/Service';

export function Prices(){
    let service = new Service();
    let token = service.getToken();

    return (
        <>

        <div className="contenedor-documentacion">
            <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
                <div className="documentacionTrackia">
                    <MenuDocumentacion />
                    <div className="doc-contenido">
                        <h1 id="ejemplos">Crea una cuenta gratis</h1>
                        <p>
                            {!token ?<a href="/account/create">Crear una cuenta desarrollador </a> 
                                   :<>Crear una cuenta desarrollador </>
                            }
                            
                            es gratis, no se requieren tarjetas de crédito y te permite:
                        </p>

                        <li>Generar hasta 3.000 transacciones diarias.</li>
                        <li>10 artefactos activos.</li>
                        <li>Hasta 2 registros por segundo por cada artefacto.</li>
                        <li>15MB de almacenamiento.</li>
                        <li>2 Ventanas mensuales de <b>Pruebas de carga</b> ilimitada.</li>
                        <li>100% vistas disponibles.</li>

                    </div>
                </div>
            </div>
        </div>
     </>);
}