import React from "react";
import { BsSearch } from "react-icons/bs";
import { JElement } from "./JElement";

export function Search(data){
    return (
        <div>
            <div >
                <BsSearch className="search"></BsSearch>
                <input  type="text" 
                        id="journalInput" 
                        name="journalInput" 
                        placeholder="Search transaction ID" 
                        autoComplete="off" 
                        onKeyDown={(event) => {data.addJournal(event, data.data)}}
                />
            </div>
            <div className='listaTransacciones'>
                {
                    data.model.map(j => (

                    <JElement 
                        key={j.transactionId}
                        selected={data.journal ? data.journal.transactionId : ""}
                        eventClick={data.selecciona} 
                        eventDelete={data.elimina}
                        data={data}
                        transactionId={j.transactionId} 
                        component={j.artifact} 
                        transactionStart={j.transactionStart} 
                        duration={j.duration} 
                        description={j.description} 
                        requestMethod={j.requestMethod} 
                        url={j.url} 
                        status={j.status}>
                    </JElement>))
                } 
            </div>
        </div>
    );
}