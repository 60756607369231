import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Anotaciones(){
    return (
        <>
          <div className="contenedor-documentacion">
            <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">
                    <h1 id="Trackia">@Trackia</h1>
                    <p>
                      Esta anotación se utiliza para iniciar al AOP que un método debe ser journalizado.
                    </p>
                    

                    <h4>Sintaxis</h4>
                    <div className="pre">
                      <p></p>
                      <p>@Trackia(</p>
                        
                        <p className="margin20 "><b className="valueType">value</b> = :step,</p>
                        <p className="margin20"><b className="valueType">description</b> = :description,</p>
                        <p className="margin20"><b className="valueType">local</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">collector</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">write</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">autoRequest</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">autoResponse</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">autoTransaction</b> = true|false,</p>
                        <p className="margin20"><b className="valueType">stereotype</b> = :stereotypes</p>
                        <p>)</p>
                    </div>

                    <h5>Parámetros de entrada</h5>
                    <p><a className="valueType">value (step):</a></p>
                    <p className="descriptionType">
                      Establece el nombre lógico del paso. 
                      Este valor es almacenado en la propiedad step de la <a href="/documentation/struct">structura</a> generado.
                    </p>

                    <p><a className="valueType">description:</a></p>
                    <p className="descriptionType">
                      Es utilizado para generar comentarios en los diagrama, 
                      se almacena en la propiedad descripción del journal.
                    </p>

                    <p><a className="valueType">local:</a></p>
                    <p className="descriptionType">
                      Indica si el resultado del step es lanzado hacia el padre.
                    </p>

                    <p><a className="valueType">collector:</a></p>
                    <p className="descriptionType">
                      Cuando esta propiedad es true, se recolecta toda la información
                      proveniente desde el procesos hijos y se establecerán como children.
                    </p>

                    <p><a className="valueType">write:</a></p>
                    <p className="descriptionType">
                      Indica si el journal actual es enviado al JournalDispatcher configurado.
                    </p>

                    <p><a className="valueType">autoRequest:</a></p>
                    <p className="descriptionType">
                      Si es verdadero, se escribirá automáticamente los parámetros del 
                      método en el campo request del journal.
                    </p>

                    <p><a className="valueType">autoRequest:</a></p>
                    <p className="descriptionType">
                      Si es verdadero, se escribirá automáticamente la respuesta del 
                      método en el campo response del journal.
                    </p>

                    <p><a className="valueType">autoTransaction:</a></p>
                    <p className="descriptionType">
                      Indica si se debe generar un transactionId de manera automática.
                      Si el journal es implementado en un método root (ejemplo un Controller)
                      se generara un nuevo transactionId, en caso contrario se utilizara el mismo
                      transactionId padre.
                    </p>

                    <p><a className="valueType">stereotype:</a></p>
                    <p className="descriptionType">
                      Establece el grupo al que pertenece el step.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </>
     );
}