import React, { useRef, useState } from 'react';
import { HeaderZone } from '../HeaderZone';
import './AdminArtifacts.css';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiArrowBack, BiPencil } from "react-icons/bi";
import { BiSend } from "react-icons/bi";

import { AiOutlinePlus } from "react-icons/ai";
import Service from '../../helpers/Service';
import { Switch, TextField } from '@mui/material';



export default class AdminArtifacts extends React.Component{
    constructor(props) {
        super(props);
        this.service = new Service();
        this.inputLabelProps = { };
        //this.inputLabelProps = { shrink: true };

        let classText = {};
        classText["name"] = "";
        classText["description"] = "";

        this.state = {
            artifacts: [],
            menu:{"display": "block"},
            detail:{"display": !window.matchMedia("(min-width: 1024px)").matches ?"none":"block"},
            iconBack:{"display":"none"},
            classText: classText,

            buttonAdd:{"display":"none"},
            buttonUpdate:{"display":"none"},
            iconUpdate:{"display":"none"},
            disabled:true,
            disabledname: false,

            id:"",
            tracking:true,
            name:"",
            description:"",
            host:"",
            minDeley:"",
            maxTransactionDay:"",
            type:"",
            request:"",
            response:"",
            modules:"",
            apiKey:"",

        };

    }

    componentDidMount() {
        this.loadArtifacts();
   }

   getAttribute(){
        return {
            id: this.state.id,
            tracking: this.state.tracking,
            name: this.state.name,
            description: this.state.description,
            host: this.state.host,
            minDeley: this.state.minDeley,
            maxTransactionDay: this.state.maxTransactionDay,
            type: this.state.type,
            request:this.state.request,
            response:this.state.response,
            modules: this.state.modules
        }
    }

    setAttribute(selected){
        if(selected){
            this.setState({id: selected.id});
            this.setState({tracking: selected.tracking});
            this.setState({name: selected.name});
            this.setState({description: selected.description});
            this.setState({host: selected.host});
            this.setState({minDeley: selected.minDeley});
            this.setState({maxTransactionDay: selected.maxTransactionDay});
            
            this.setState({type: selected.type});
            this.setState({request: selected.request});
            this.setState({response: selected.response});
            this.setState({modules: selected.modules});
            this.setState({apiKey: selected.apiKey});
        }else{
            this.setState({id: ""});
            this.setState({tracking: true});
            this.setState({name: ""});
            this.setState({description: ""});
            this.setState({host: ""});
            this.setState({minDeley: ""});
            this.setState({maxTransactionDay: ""});
            
            this.setState({type: ""});
            this.setState({request: ""});
            this.setState({response: ""});
            this.setState({modules: ""});
            this.setState({apiKey: ""}); 
        }
}

    selectItem(selected){
        this.setAttribute(selected);
        this.setState({disabled: true});
        this.setState({iconUpdate: {display: "block"}});
        
        let classText = {};
        classText["name"] = "";
        classText["description"] = "";
        this.setState({classText: classText});

        this.setState({buttonAdd: {"display": "none"}});
        this.setState({buttonUpdate: {"display": "none"}});

        if(!window.matchMedia("(min-width: 1024px)").matches){
            this.setState({detail: {"display": "block"}})
            this.setState({menu: {"display": "none"}})
            this.setState({iconBack: {"display": "block"}})
        }
    }

    back(){
        this.setState({detail: {"display": "none"}})
        this.setState({menu: {"display": "block"}})
        this.setState({iconBack: {"display": "none"}})
    }



    async delete(id){
        
        await this.service.deleteArtifact(id);
        this.setAttribute();
        this.loadArtifacts();
        this.setState({iconUpdate: {"display": "none"}});
        if(!window.matchMedia("(min-width: 1024px)").matches){
            this.setState({detail: {"display": "none"}})
            this.setState({menu: {"display": "block"}})
        }
    }

    setStateButonAction(){
        this.setState({buttonAdd: {"display": "none"}});
        this.setState({buttonUpdate: {"display": "none"}});
        this.setState({iconUpdate: {"display": "block"}});
        this.setState({disabled: true});

        if(!window.matchMedia("(min-width: 1024px)").matches){
            this.setState({detail: {"display": "none"}})
            this.setState({menu: {"display": "block"}})
            this.setState({iconBack: {"display": "none"}})
        }
    }

    update(){
        this.setState({disabled: false});
        this.setState({disabledname: true});

        this.setState({buttonAdd: {"display": "none"}});
        this.setState({buttonUpdate: {"display": "block"}});
    }

    isValidForm(){
        let isValid = true;
        let classText = this.state.classText;
        if(this.state.name == ""){
            classText["name"] = "blink";
            isValid = false;
        }

        if(this.state.description == ""){
            classText["description"] = "blink";
            isValid = false;
        }

        this.setState({classText: classText});
        return isValid;
    }

    async actualizar(){
        if(this.isValidForm()){
            let response = await this.service.updateArtifact(this.getAttribute());
            this.loadArtifacts();
            this.setStateButonAction();
            this.setState({apiKey: response.data.apiKey});
        }
    }

    /**
     * Evento que se produce cuando el usuario presiona el boton flotante agregar.
     * Deja el formulario en modo agregar
     */
    add(){
        this.setAttribute();
        this.setState({disabled: false});
        this.setState({disabledname: false});
        

        this.setState({buttonAdd: {"display": "block"}});
        this.setState({buttonUpdate: {"display": "none"}});

        let classText = {};
        classText["name"] = "";
        classText["description"] = "";
        this.setState({classText: classText});

        if(!window.matchMedia("(min-width: 1024px)").matches){
            this.setState({detail: {"display": "block"}})
            this.setState({menu: {"display": "none"}})
            this.setState({iconBack: {"display": "block"}})
        }
    }

    async agregar(event){
        if(this.isValidForm()){
            let response = await this.service.registerArtifact(this.getAttribute());
            this.setState({apiKey: response.data.apiKey});

            this.loadArtifacts();
            this.setStateButonAction();
        }
    }

    async loadArtifacts () {
        let self = this;

        let list = await this.service.getArtifacts();
        self.setState({artifacts: list});

    };

    handleChange(self){
        self.setState({tracking: !self.state.tracking});
    };

    setValueForm(field, val) {
        this.setState({[field]: val});

        let classText = this.state.classText;
        if(classText[field] != undefined){
            classText[field] = "";
            this.setState({[classText]: classText});
        }
    }

    render() {
        let self = this;

        return (
            <>
            <div className="contenedor-documentacion">
            <HeaderZone iniciar={true}/>
                <div className="inicioMantenedor">
                    <div className='listaMantenedor' style={this.state.menu}>
                        {this.state.artifacts.map(element => (
                            <div className='elementMantenedor hover' key={element.id}
                            onClick={() => {this.selectItem(element)}}>
                                <div className='avatar sesionSettingIcon radius-8cqb '>
                                    {element.name ?element.name.substring(0,1):""}
                                </div>
                                <p className="mantenedorText bold">{element.name}</p>
                                <p className="mantenedorText">{element.description}</p>

                                <div className="eliminaMantenedor eliminaSelected" onClick={() => this.delete(element.id)}>
                                    <AiOutlineDelete size={20}  />
                                </div>
                                
                            </div>
                        ))} 

                        <div className='boxMenu radius-8cqb'>
                            <AiOutlinePlus className='addIcon' onClick={() => {this.add()}} size={30}></AiOutlinePlus>
                        </div>
                    </div>
                    <div className='backIcon' style={this.state.iconBack} onClick={() => {this.back()}}>
                            <BiArrowBack size={22}></BiArrowBack>
                        </div>

                    <div className='detalleMantenedor' style={this.state.detail} >

                        <div className='updateIcon radius-8cqb' style={this.state.iconUpdate} onClick={() => {this.update()}}>
                            <p><BiPencil size={30}></BiPencil></p>
                        </div>

                        <div className='updateIcon radius-8cqb' style={this.state.buttonUpdate} onClick={() => {this.actualizar()}}>
                            <p><BiSend size={30}></BiSend></p>
                        </div>

                        <div className='updateIcon radius-8cqb' style={this.state.buttonAdd} onClick={() => {this.agregar()}}>
                            <p><BiSend size={30}></BiSend></p>
                        </div>

                        <div className="formCreate">
                            <h5>Artifact</h5>
                                <div className="user-input-wrp">
                                Off
                                <Switch 
                                    disabled={this.state.disabled} 
                                    checked={this.state.tracking} 
                                    onChange={() => {this.handleChange(this)}}
                                />
                                On
                                </div>

                                <div className="user-input-wrp">
                                    <div className='textBox'>
                                        <TextField 
                                            id="name"
                                            label="Name" 
                                            required
                                            className={'textField ' + this.state.classText["name"]}
                                            onChange={event => this.setValueForm("name", event.target.value)} 
                                            disabled={this.state.disabled || this.state.disabledname} 
                                            value={this.state.name} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    <div className='textBox'>
                                        <TextField 
                                            label="Description" 
                                            required
                                            className={'textField ' + this.state.classText["description"]}
                                            onChange={event => this.setValueForm("description", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.description} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    
                                    <div className='textBox'>
                                        <TextField 
                                            label="Min Deley (ml)" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("minDeley", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.minDeley} 
                                            type='number'
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    
                                    <div className='textBox'>
                                        <TextField 
                                            label="Max Trasaction Day" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("maxTransactionDay", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.maxTransactionDay} 
                                            type='number'
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>
                                    <div className='textBox'>
                                        <TextField 
                                            label="Host" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("host", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.host} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    <div className='textBox'>
                                        <TextField 
                                            label="Type" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("type", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.type} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    <div className='textBox'>
                                        <TextField 
                                            label="Request" 
                                            className='textField'
                                            onChange={event => this.setValueForm("request", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.request} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    <div className='textBox'>
                                        <TextField 
                                            label="Response" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("response", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.response} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>
                                    
                                    <div className='textBox'>
                                        <TextField 
                                            label="Modules" 
                                            className='textField' 
                                            onChange={event => this.setValueForm("modules", event.target.value)} 
                                            disabled={this.state.disabled} 
                                            value={this.state.modules} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>

                                    <div className='textBox'>
                                        <TextField 
                                            label="ApiKey" 
                                            className='textField' 
                                            disabled={true} 
                                            value={this.state.apiKey} 
                                            InputLabelProps={this.inputLabelProps}
                                        />
                                    </div>
                                    <br></br>

                                    
                                </div>
                       </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}