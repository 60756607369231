import React, { useState } from 'react';
import ReactFlow from 'reactflow';
import { JournalVisualizar } from './JournalVisualizar';

import 'reactflow/dist/style.css';

export function ComponentDiagram(data){
  if(data.journal && data.journal.transactionId && data.nodes && data.edges){
    data.onEditCode(this, data.journal);
    return <>
      <div className="componentDiagram" >
        <JournalVisualizar journal={data.journal}/>
        

        <div className="reactFlowDiagram" style={data.reactFlowDiagramStyle}>
          <ReactFlow
            nodes={data.nodes}
            edges={data.edges}
            onClick={data.click}
            onNodeClick={data.onEditCode}
          />
        </div>
      </div>
      
      </>
  }else{
      return (<></>);
  }
}