import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Modulos(){
    return (
        <>
          <div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">



            <h1 id="Modulos">Módulos</h1>
            <p>
            Los módulos son extensiones de la información trackeada, 
            en principio TrackIA implementa 3 módulos nativos, pero es posible 
            implementar sus propios módulos de negocio.
            </p>

            <div className="">
            
            <h4>@Slable(slaModule)</h4>
            <p>
                Módulo que registra si el paso fue ejecutado dentro del tiempo máximo comprometido.
            </p>

            <h5>Parámetros de entrada</h5>
            <p><a className="valueType">value</a></p>
            <p className="descriptionType">
            Tiempo máximo de respuesta comprometido.
            </p>

            <h5>Resultado</h5>
            <p><a className="valueType">sla</a></p>
            <p className="descriptionType">
            Contiene el valor SLA comprometido.
            </p>

            <p><a className="valueType">inSla</a></p>
            <p className="descriptionType">
            Indica true si el valor el tiempo de respuesta no supera el value comprometido.
            </p>

            <p><a className="valueType">inSlaProvider</a></p>
            <p className="descriptionType">
            Indica true si todos los servicios invocados están dentro de su SLA comprometido.
            </p>




            <h4>@Infoable(sysInfoModule)</h4> 
            <p>
            Módulo que permite registrar información del sistema operativo que aloja 
            el servicio al momento de ejecutar el paso.
            </p>       

            <h5>Parámetros de entrada</h5>
            <p>No requiere parámetros de entrada.</p>

            <h5>Resultado</h5>
            <p><a className="valueType">maxMemory</a></p>
            <p className="descriptionType">
            Devuelve la cantidad total de memoria en la máquina virtual Java. 
            </p>

            <p><a className="valueType">availableProcessors</a></p>
            <p className="descriptionType">
            Devuelve el número de procesadores disponibles para la máquina virtual Java. 
            </p>

            <p><a className="valueType">runtimeVersion</a></p>
            <p className="descriptionType">
            Indica la Java Runtime Environment.
            </p>


            <p><a className="valueType">journalRelease</a></p>
            <p className="descriptionType">
            Versión de TrackIA que se definió en proyecto.
            </p>



            <h4>@Indexable(indexableModule)</h4> 
            <p>
            Módulo que permite agregar expresiones evaluadas en como campos 
            especiales dentro del journal. Es útil para implementar indices de 
            búsquedas específicos para artefactos de negocio.
            </p>   

            <h5>Parámetros de entrada</h5>
            <p><a className="valueType">onCreate</a></p>
            <p className="descriptionType">
            Evento que se ejecuta cuando se crea el paso.
            </p>


            <p>Ejemplo:</p>
            <div className="pre">
            {
                `@Indexable (onCreate = "{type:$(/request/0/value/type)}")`
            }
            </div>

            <p>
            En el ejemplo anterior, se crea una propiedad de nombre <b>type </b> obtenida del 
            primer parámetro recibido en el paso.
            </p>

            <p><a className="valueType">postProcess</a></p>
            <p className="descriptionType">
            Evento que se ejecuta después de ejecutar el paso. Ejemplo:
            </p>

            <div className="pre">
            {
                `@Indexable (postProcess = "{user:$(/response/body/user)}")`
            }
            </div>
            <p>
            En el ejemplo anterior, se crea una propiedad de nombre <b>user </b> 
            obtenida de la respuesta del método ejecutado.
            </p>
            </div>



            </div>
            </div>
            </div>
        </div>
     </>);
}