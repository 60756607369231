import React from "react";
import jwt_decode from "jwt-decode";


import './Setting.css';
import { MdAccountBalance, MdAppShortcut, MdClose } from "react-icons/md";
import { HiLockClosed } from "react-icons/hi2";
import Service from '../../helpers/Service';

export function Setting(data) {
    let token = localStorage.getItem("authorization");
    let srv = new Service();
    
    let user = {firstName: "Anonimo", roles:[]};

    if(token!=null){
        let decoded = jwt_decode(token);
        user = decoded.user;
    }


    function isAdmin(user){
        for(const element of user.roles){
            if(element.name === 'ROLE_ROOT' || element.name === 'ROLE_ADMIN'){
                return true;
            }
        }

        return false;
    }

    const cerrar = (user) => {
        srv.disconnect();
        window.location.href = '/';
      }


    return (
        <div className="inicioSessionFrame">
                <div className="inicioSession aparece-izquierda" style={data.settingStyle}>
                    <div className="userData">
                        <div className="closeWin" onClick={() => {
                            data.setDisplay({"display": "none"})}
                        }>
                            <MdClose size={25} className="icon hover"></MdClose>
                        </div>

                        <div >
                            <div className='sesionSettingIcon radius-8cqb select-none hover'>
                                <p>{user.firstName.substring(0,1)}</p>
                            </div>
                        </div>

                        <div className='sesionUser'>
                            <h5>{user.firstName} {user.lastName}</h5>
                            <h5>{user.email}</h5>

                            <h5>You account id is:</h5>
                            <h4>{user.accountId}</h4>
                            
                        </div>
                    </div>
                    <div className="menuUser">
                        <section className="sesionMenu" style={{'display': isAdmin(user) ?'block' :'none'}}>
                            <br></br>
                            <div className="hover-menu"><a href="/account/update"><MdAccountBalance size={20} className="icon"></MdAccountBalance> Corporate Account</a></div>
                            <div className="hover-menu"><a href="/admin/artifacts"><MdAppShortcut size={20} className="icon"></MdAppShortcut> Manage Artifacts</a></div>
                        </section>

                        <section className="sesionMenu">
                            <br></br>
                            <div className="hover-menu"><a onClick={() => {cerrar()}}><HiLockClosed size={20} className="icon"></HiLockClosed> Sign off</a></div>
                        </section>
                    </div>

                    
                </div>
            </div>
    );
}