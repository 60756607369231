import { useRouteError } from "react-router-dom";


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { HeaderZone } from "./components/HeaderZone";

export default function ErrorPage() {
  const error = useRouteError();
    return (
      <>
        <div className="contenedor-principal">
          <HeaderZone />
          <div className="cuerpo">
            <center>
              <div className="superior backgroundcolor">
                  <h1>Oops!</h1>
                  <h2>Sorry, an unexpected error has occurred.</h2>
                  <p>
                    <i>{error.statusText || error.message}</i>
                  </p>
              </div>
            </center>
          </div>
        </div>
      </>
    );
  }