import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, Button, TextField } from '@mui/material';

import AccountContext from "./AccountContext";
import Service from '../../../helpers/Service';

export default function AddressForm({setActiveStep}) {
  const context = React.useContext(AccountContext);

  const service = new Service();

  const [classText, setClassText] = React.useState([]);
  const [address1, setAddress1] = React.useState(context.address1);
  const [address2, setAddress2] = React.useState(context.address2);
  const [city, setCity] = React.useState(context.city);
  const [state, setState] = React.useState(context.state);
  const [zip, setZip] = React.useState(context.zip);
  const [country, setCountry] = React.useState(context.country);

  const setValueForm = (field, val) => {
    context[field] = val;

    let localClassText = classText ;
    localClassText[field] = "";
    setClassText(localClassText);
  }

  const handleNext = async () => {
    if(isValid()){
      let body = 
      {
        "name": context.companyName,
        "description": context.companyDescription,
        "sector": context.sectorEmpresa,
        "sitio": context.sitioEmpresa,
    
        "root": {
            "firstName": context.firstName,
            "lastName": context.lastName,
            "phone": context.phone,
            "email": context.email,
            "password": context.password
        },
    
        "street": {
            "address1": context.address1,
            "address2": context.address2,
            "city": context.city,
            "state": context.state,
            "zip": context.zip,
            "country": context.country
        }
      };


      let account = await service.createAccount(body);
      if(account){
        context.id = account.id;
        setActiveStep(3);
      }
    }
  }

  const handleBack = () => {
    setActiveStep(1);
  }

  const valid = ["address1", "city", "state", "country"];
  const isValid = () => {
    let isValid = true;

    let classText = [];
    for(let i=0; i<valid.length; i++){
      let name = valid[i];
      if(context[name] == "" || context[name] == undefined){
        classText[name] = "blink";
        isValid = false;
      }else{
        classText[name] = "";
      }
    }

    setClassText(classText);
    return isValid;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Address Company
      </Typography>
      <Grid item xs={12}>
          <TextField
            required
            id="address1"
            value={address1}
            className={classText["address1"]}
            onChange={event => {setValueForm("address1", event.target.value); setAddress1(event.target.value);}} 
            name="address1"
            label="Address line 1"
            fullWidth
            variant="standard"
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            id="address2"
            value={address2}
            onChange={event => {setValueForm("address2", event.target.value); setAddress2(event.target.value);}} 
            name="address2"
            label="Address line 2"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            value={city}
            className={classText["city"]}
            onChange={event => {setValueForm("city", event.target.value); setCity(event.target.value);}} 
            name="city"
            label="City"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            value={state}
            className={classText["state"]}
            onChange={event => {setValueForm("state", event.target.value); setState(event.target.value);}} 
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="zip"
            value={zip}
            onChange={event => {setValueForm("zip", event.target.value); setZip(event.target.value);}} 
            name="zip"
            label="Zip / Postal code"
            fullWidth
             variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            value={country}
            className={classText["country"]}
            onChange={event => {setValueForm("country", event.target.value); setCountry(event.target.value);}} 
            name="country"
            label="Country"
            fullWidth
             variant="standard"
          />
        </Grid>

      

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => {handleBack()}}
                    sx={{ mt: 3, ml: 1 }}>
                      Back
          </Button>

          <Button
                    variant="contained"
                    onClick={() => {handleNext()}}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Create
          </Button>
      </Box>
    </React.Fragment>
  );
}