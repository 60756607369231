import React, { useState } from "react";
import { HeaderZone } from "../HeaderZone";
import { Box, Button, Container, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import Service from "../../helpers/Service";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RenderAction } from "./RenderAction";
import dayjs from 'dayjs';
import { BiRefresh, BiArrowBack } from "react-icons/bi";
import { BsSearch, BsArrowUp } from "react-icons/bs";

import '../theme/Dark.css';
import './style.css';
import { format } from "date-fns";
import moment from "moment/moment";

export default class Sumary extends React.Component{
  constructor(props) {
    super(props);
    this.service = new Service();
    this.skip = 0;
    this.sort = {'field': "trasactionId", 'sort': 'asc'}

    let transactionStart = localStorage.getItem("transactionStart")
    let transactionEnd = localStorage.getItem("transactionEnd");

    let desde = transactionStart ? dayjs(transactionStart) :dayjs();
    let hasta = transactionEnd ? dayjs(transactionEnd) :desde.add(1, 'day');

    this.isMobile = !window.matchMedia("(min-width: 1000px)").matches ?true:false;
    this.state = {
      transactionStart : desde,
      transactionEnd : hasta,
      limit : 100,

      status : '',
      artifact : '',
      rows : [],
      rowsTrack : {size:0, list:[]},
      areaSumary : {"display": "block"},
      areaTrackList : {"display": "none"},
      dataBoxStyle:{"display": "block"},
      gridTableStyle:{"height": "calc(100% - " + (this.isMobile ? 240: 320) + "px)"},
      dataGridStyle:{"height": "calc(100% - " + (this.isMobile ? 240: 290) + "px)"}
    }
  }

  async componentDidMount() {
      this.load();
  }
  
  async load(){
    let token = this.service.getToken();
    if(token){
      let summary = await this.service.getSummary(this.state.transactionStart, this.state.transactionEnd);
      this.setState({rows: summary})

      this.setState({areaSumary: {display : "block"}});
      this.setState({areaTrackList: {display : "none"}});
    }

  }

  async onCellClick2(status, artifact){
    if(status == "ok" || status == "nok"){
      this.setState({areaSumary: {display : "none"}});
      this.setState({areaTrackList: {display : "block"}});

      this.skip = 0;
      this.status = status;
      this.artifact = artifact;

      await this.rowUpdate(0);
    }
  }
  async onCellClick(params, event){
    if(params.field != "artifact"){
      var status = params.field;
      if(status == "ok" || status == "nok"){
        this.setState({areaSumary: {display : "none"}});
        this.setState({areaTrackList: {display : "block"}});

        this.skip = 0;
        this.status = status;
        this.artifact = params.row.artifact;

        await this.rowUpdate(0);
      }
    }
  }

  async rowUpdate(){
    const newRowsTrack = await this.service.journalSearch(
      this.artifact, 
      this.status, 
      this.state.transactionStart, 
      this.state.transactionEnd, 
      this.state.limit, 
      this.skip,
      this.sort,
    );
    this.setState({rowsTrack: newRowsTrack});
  }

  
  async sortModelChange(params){
    if(params && params[0] && params[0].field){
      this.sort = params[0]; 
      await this.rowUpdate();
    }
  }

  async onPaginationModelChangeDetail(params, event){
    this.skip = params.page * params.pageSize;
    await this.rowUpdate();
  }

  async goBack(params, event){
    this.setState({areaSumary: {display : "block"}});
    this.setState({areaTrackList: {display : "none"}});
  }

  async dateRangeButtonClic(event){
    let current = this.state.dataBoxStyle.display;

    this.setState({dataBoxStyle: {display : current == "block" ? "none" : "block"}});
    this.setState({gridTableStyle: {height: current == "block" ? "calc(100% - 170px)" : "calc(100% - 250px)"}});
    this.setState({dataGridStyle: {height: current == "block" ? "calc(100% - 160px)" : "calc(100% - 240px)"}});
  }

  
  render() {
    const columnsTrack = [
      { field: 'transactionId', headerName: 'Transaction Id', width: this.isMobile?250:350, sortable: false, headerClassName: 'super-app-theme--header' },
      { field: 'transactionStart', valueFormatter: params =>  moment(params?.value).format("DD/MM/YYYY hh:mm A"), headerName: 'Create', width: this.isMobile?170:250, format: 'Date', sortable: true, headerClassName: 'super-app-theme--header' },  
      { field: 'duration', headerName: 'Duration', width: 80, sortable: true,  headerClassName: 'super-app-theme--header' },  
      { field: 'status', headerName: 'Status', width: 100, sortable: false, headerClassName: 'super-app-theme--header' },
      { field: 'action', headerName: 'Action', width: 'calc:(100% - 180px)', flex: 1, sortable: false, renderCell: RenderAction, headerClassName: 'super-app-theme--header'},
    ];

    
    return (
      <React.Fragment>
          <HeaderZone iniciar={true}></HeaderZone>

          <Container className='create-account-home'>
            <Paper variant="outlined" className="titleSuperior" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              
              <Typography component="h1" variant="h5" align="center" className="paramBox">
              <Box>
              
                <Typography className='title' component="h1" variant="h3" align="center">
                Artifact Summary
                </Typography>

                <Typography className='range' component="h1" variant="h5" align="center">
                  Search Range

                  <Box className="butonRango">
                    <IconButton style={{'color': 'var(--main-background-color)',  'backgroundColor': 'transparent'}}  
                          onClick={(event) => {this.dateRangeButtonClic(event)}
                      }>
                      
                      <BsSearch size={25}  style={{'display': this.state.dataBoxStyle.display == 'block' ?'none' :'block'}}/>
                      <BsArrowUp size={15}  style={{'display': this.state.dataBoxStyle.display}}/>
                    </IconButton>
                  </Box>

                </Typography>


                <Box className="dataBox" style={this.state.dataBoxStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                  id="desde"
                  name="desde"
                  label="Begin"
                  value={this.state.transactionStart}
                  format="DD-MM-YYYY"
                  className="DatePicker"
                  onChange={date => {
                    this.setState({transactionStart: date});
                    let f = (date.year() + "/" + ((date.month()*1)+1) + "/" + (date.$D)  );
                    localStorage.setItem("transactionStart", f);
                  }}
                  />
                </LocalizationProvider>


                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                  id="hasta"
                  name="hasta"
                  label="End"
                  value={this.state.transactionEnd}
                  format="DD-MM-YYYY"
                  className="DatePicker"
                  onChange={date => {
                    this.setState({transactionEnd: date});
                    let f = (date.year() + "/" + ((date.month()*1)+1) + "/" + (date.$D)  );
                    localStorage.setItem("transactionEnd", f);
                  }}

                  />
                </LocalizationProvider>

                <box className="iconRefresh">
                  <IconButton aria-label="Refrescar" style={{'color': 'var(--main-background-color)'}}  
                    onClick={
                      () => this.load()
                    }>
                    <BiRefresh  size={50}  />
                </IconButton>
                </box>

                </Box>
                
              </Box>

              </Typography>

              <Box className="dataArea">
                

              <div style={this.state.areaSumary}>
                <div className="gridTable" style={this.state.gridTableStyle}>

                <div className="artifactElementTitle">
                      <p className="artifactTitle titleStatusName">Name</p>
                      <p className="artifactTitle titleStatusok">OK</p>
                      <p className="artifactTitle titleStatusnok">NOK</p>
                </div>

                {this.state.rows.map(j => (
                  <div className="artifactElement divArtifact" >
                    <div className="">
                      <p className="artifactText name">{j.id}</p>
                      <p className="artifactText ok valuestatus"
                        onClick={(event) => {
                          this.onCellClick2('ok', j.id);
                        }}>{j.ok}</p>
                      <p className="artifactText nok valuestatus "
                        onClick={(event) => {
                          this.onCellClick2('nok', j.id);
                        }}>{j.nok}</p>
                      
                    </div>
                  
                  </div>
                ))
                } 
                </div>
              </div>




              <div className="areaTrackList" style={this.state.areaTrackList}>
              <Box className="iconBack" >
                
                <IconButton aria-label="Volver" style={{'color': 'var(--main-background-color)'}}  
                    onClick={() => this.goBack()
                  }>
                  <BiArrowBack size={20}  />
                </IconButton>

                <Box>
                  {this.artifact}/{this.status}
                </Box>

              </Box>

              <div className="gridDataGrid" style={this.state.dataGridStyle}>

                  <DataGrid
                    classes="row"
                    rows={this.state.rowsTrack.list}
                    columns={columnsTrack}
                    rowCount={this.state.rowsTrack.pageCount}

                    columnVisibilityModel={{
                      'transactionId': !this.isMobile,
                      'status': !this.isMobile 
                    }}

                    paginationMode="server"
                    onPaginationModelChange={(params, event) => {this.onPaginationModelChangeDetail(params, event);}}
                    onSortModelChange={(params) => {this.sortModelChange(params);}}
                />
                </div>
              </div>

              </Box>

              </Paper>
          </Container>
      </React.Fragment>
    );
  }
}