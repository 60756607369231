import React from "react";
import { format } from 'date-fns'
import { VscDebugDisconnect, VscError, VscPass } from "react-icons/vsc";
import { TbPlugConnectedX } from "react-icons/tb";

export function JournalVisualizar(data){

    if(!data.journal.transactionId){
        return (<></>);
    }

    let response = data.journal.response != null && data.journal.response.body != null ? JSON.stringify(data.journal.response.body, null, 4) :'';
    let transactionStart = new Date(data.journal.transactionStart);
    let transactionEnd = new Date(data.journal.transactionEnd);
    let dateFormat = 'dd/MM/yyyy hh:mm:ss';
    let statusCode = data.journal.response && data.journal.response.statusCode ? data.journal.response.statusCode : "";
    let statusCodeValue = data.journal.response && data.journal.response.statusCodeValue ? data.journal.response.statusCodeValue : "";
    let status = data.journal.status ? data.journal.status : "";

    let sla = data.journal.rootElement && data.journal.modules && data.journal.modules.sla ?data.journal.modules.sla :undefined;
    let sysInfo = data.journal.rootElement && data.journal.modules && data.journal.modules.sysInfo ?data.journal.modules.sysInfo :undefined;

    return (
        

        <div>
            <div className="resumenDialog">
                <div className="resumen-status">
                    <center>
                        <p className={status === "OK" ?"status2 OK" :"status2 NOK"}>{status}</p>
                    </center>
                    <p className="status2-text">{statusCodeValue} {statusCode}</p>
                </div>

                <p>{data.journal.transactionId}</p>
                <br/>
                <p>{data.journal.artifact}</p>
                <p className="maxSize">{data.journal.description}</p>
                <p className="maxSize">{data.journal.requestMethod} {data.journal.url}</p>
                <p>Start: {format(transactionStart, dateFormat)}</p>
                <p>End: {format(transactionEnd, dateFormat)}</p>
                <p>Duration: {data.journal.duration}ms</p>


                {
                    (sla
                        ?
                        <div className="sla-info">
                        <div className="">
                            <p className={sla.inSla ?"status2 OK center" :"status2 NOK center"}>
                                SLA
                            </p>
                        </div>
                        <div className="sla-title">{sla.sla}</div>
                        </div>

                        :<></>
                    )
                }

                <div className="connectivity-info">
                <div className="">
                {
                    (data.journal.connectivityError
                        ?  <p className="status2 NOK center"><TbPlugConnectedX size={17} /></p>
                        :  <p className="status2 OK center"><VscDebugDisconnect size={17} /></p>
                    )
                }
                </div>
                <div className="connectivity-title">Connectivity</div>
                </div>



                {
                    (sysInfo && sysInfo.runtimeVersion
                        ?
                        <div className="javaversion-info">
                        <div className="javaversion-value"><p>{sysInfo.runtimeVersion}</p></div>
                        <div className="javaversion-title">Java Runtime Environment</div>
                        </div>

                        :<></>

                    )
                }
            </div>

  

        </div>
    );

}