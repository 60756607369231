import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import AccountContext from "./AccountContext";
import { Box, Button } from '@mui/material';

export default function CompanyForm({setActiveStep}) {
  const context = React.useContext(AccountContext);

  const [classText, setClassText] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(context.companyName);
  const [companyDescription, setCompanyDescription] = React.useState(context.companyDescription);
  const [webSite, setWebSite] = React.useState(context.webSite);

  const setValueForm = (field, val) => {
    context[field] = val;
    
    let localClassText = classText ;
    localClassText[field] = "";
    setClassText(localClassText);
  }

  const handleNext = async() => {
    let valid = await isValid();
    if(valid){
      setActiveStep(2);
    }
  }

  const handleBack = () => {
    setActiveStep(0);
  }

  const valid = ["companyName"];
  const isValid = async () => {
    let isValid = true;

    let classText = [];
    setClassText(classText);
    for(let i=0; i<valid.length; i++){
      let name = valid[i];
      if(context[name] == "" || context[name] == undefined){
        classText[name] = "blink";
        isValid = false;
      }else{
        classText[name] = "";
      }
    }

    setClassText(classText);
    return isValid;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Company
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="companyName"
            label="Name"
            className={classText["companyName"]}
            value={companyName}
            onChange={event => {setValueForm("companyName", event.target.value); setCompanyName(event.target.value)}} 
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="companyDescription"
            value={companyDescription}
            onChange={event => {setValueForm("companyDescription", event.target.value); setCompanyDescription(event.target.value)}} 
            label="Description"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="webSite"
            value={webSite}
            onChange={event => {setValueForm("webSite", event.target.value); setWebSite(event.target.value)}} 
            label="Web Site"
            fullWidth
            variant="standard"
          />
        </Grid>

      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => {handleBack()}}
                    sx={{ mt: 3, ml: 1 }}>
                      Back
          </Button>

          <Button
                    variant="contained"
                    onClick={() => {handleNext()}}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
          </Button>
      </Box>

    </React.Fragment>
  );
}