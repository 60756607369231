import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Dispatcher(){
    let ident1 = "\u00A0\u00A0";
    let ident2 = "\u00A0\u00A0\u00A0\u00A0";
    return (
        <>
          <div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">

                <h1 id="Dispatcher">Dispatcher</h1>
                <p>
                  El <b>Dispatcher</b> establece 
                  el mecanismo de persistencia, las opciones disponibles son 
                  trackiaLoggerDispatcher y trackiaServerDispatcher, pero otras 
                  extensiones de negocio pueden ser programadas.
                </p>

                <p>Para establecer el dispatcher, basta con establecer el parámetro <b>trackia.dispatcher.class</b></p>

                <p>
                  El dispatcher configurado será llamado cuando el método trackaeado
                  contenga el parámetro <b>write</b> = <b>true</b>, generalmente 
                  en un Controller, aunque puede ser indicado en cualquier método 
                  si así se requiere.
                </p>

                <h3>Dispatcher disponibles</h3>
                <p><a className="valueType">trackiaLoggerDispatcher</a></p>
                <p className="descriptionType">
                  Envía la salida por Log4j2 en formato json. 
                  Puede utilizar este dispatcher si no posee un servicio que 
                  de percistencia. Si utiliza este dispatcher, 
                  la transacción no podrá ser consultada.
                </p>

                <p><a className="valueType">trackiaServerDispatcher</a></p>
                <p className="descriptionType">
                  Envía la salida a http post a la url definida 
                  en la propiedad <b>trackia.dispatcher.url</b>.  
                  Puede configurar Trackia Cloud como url de destino o incluso programar su propia 
                  API y almacenarlo en su base de datos.
                </p>        



              <h3>Ejemplos</h3>

              <p>El siguiente ejemplo, envía la salida al log configurado</p>
              <div className="pre">
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.class: </a><a className="descriptionType ">trackiaLoggerDispatcher</a></p>
              </div>

              <p>El siguiente ejemplo, envía la salida a Trackia Cloud.</p>
              <div className="pre">
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.class: </a><a className="descriptionType ">trackiaServerDispatcher</a></p>
                    <p><a className="valueType">trackia.dispatcher.url: </a><a className="descriptionType ">https://trackiaapp.com/journal</a></p>
                    <p><a className="valueType">trackia.dispatcher.auth: </a><a className="descriptionType ">https://trackiaapp.com/auth/signin</a></p>
              </div>

              <p>El siguiente ejemplo, envía la salida por POST a una url de negocio, donde podrás almacenarlo en tu propia base de datos.</p>
              <div className="pre">
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.class: </a><a className="descriptionType ">trackiaServerDispatcher</a></p>
                    <p><a className="valueType">trackia.dispatcher.url: </a><a className="descriptionType ">http://localhost/example</a></p>
              </div>

              <h3>Crear un dispatcher personalizado</h3>

              <p>
                En algunas ocasiones, puedes necesitar enviar la salida a un componente personalizado.
                Trackia implementa un mecanismo modular, al que se pueden anexar nuevas formas de almacenamiento.
              </p>

              <p>
                <li><b>Paso 1</b>: Implementación java: La clase debe implementar interfase TrackiaDispatcher</li>
                <div className="pre">
                  <br/>
                  <p>
                    <a className="import-text">@Service</a>
                    <a className="valueType">("myBusinessDispatcher")</a>
                    
                  </p>
                  <p>
                    
                    <a className="import-text">public class </a> 
                    <a className="valueType">MyBusinessDispatcher</a>
                    <a className="import-text"> implements </a>
                    <a className="valueType"><u>TrackiaDispatcher</u></a>
                    <a>{"{"}</a>
                  </p>


                  <p><a className="import-text">{ident1}@Override</a></p>
                  <p><a className="import-text">{ident1}public void</a> send(Journal journal) {"{"}</p>
                  <p>{ident2}//Implement</p>
                  <p>{ident1+"}"}</p>
                  <p>{"}"}</p>
                </div>
              </p>

                <li><b>Paso 2</b>: application.yml:</li>


              <div className="pre">
                    <br/>
                    <p><a className="valueType">trackia.dispatcher.class: </a><a className="descriptionType ">myBusinessDispatcher</a></p>
              </div>

                </div>
            </div>
        </div>
    </div>
    </>);
}