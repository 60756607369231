import React from 'react';
import Moment from 'moment';
import { AiOutlineDelete, AiOutlineMenu } from 'react-icons/ai';

export function JElement({transactionId, selected, description, requestMethod, url, component, transactionStart, status, duration, eventClick, eventDelete, data}){
    
        if(status){
            let classBox = selected === transactionId ? "journalElementSelected" : "journalElement";
            let classText = selected === transactionId ? "textElementSelected" : "textElement";

            return (
                <>
            <div className={classBox}  onClick={() => eventClick(transactionId)} key={transactionId}>
                <p className={status === "OK" ?"status radius-2cqb OK" :"status radius-2cqb NOK"}>{status}</p>
                <div className={classBox = selected === transactionId ? "elimina eliminaSelected" : "elimina"} 
                    onClick={() => eventDelete(transactionId, data)}>
                    <AiOutlineDelete size={17}  />
                </div>
                

                <p className={classText + " bold"}>{component}</p>
                <p className={classText}>{description}</p>
                <p className={classText + " small"} ><b>{requestMethod}</b> {url}</p>
                
            </div></>);
        }else{
            return (
                <><div></div></>
            );
        }
    
}