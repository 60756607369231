import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Struct(){
    return (
        <>
          <div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">

                <h1 id="eljournal">Struct</h1>
                  <p>
                    Este sección describe la estructura de datos generada cuando se almacena la traza de un proceso.
                  </p>

                  <p>
                    La estructura contiene un árbol con la información de proceso ejecutado, 
                    los subprocesos son añadidos como hijos en el campo children, de esta manera se representa 
                    la traza completa de una operación. 
                    
                    Si otros artefactos son ejecutados dentro de la misma 
                    transacción, se crea una segunda estructura con un 
                    mismo <b>trasactionId.</b> 
                  </p>

                  <p>Los propiedades de la estructura se describen a continuación:</p>
                  <div className="">
                      <p><a className="classType">String </a> 
                      <a className="valueType">id</a></p>
                      <p className="descriptionType">
                        Identificador único del registro. 
                        Este valor es generado automáticamente al momento de crear el registro.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">transactionId</a></p>
                      <p className="descriptionType">
                        Contiene el número asociado a la transacción ejecutada. 
                        Si otros servicios son llamado dentro de la misma transacción, 
                        todas las estructuras generados comparten este mismo valor.

                        El transactionId es generado mediante la función randomUUID de UUID java,
                        lo que garantiza un números pseudoaleatorios criptográficamente fuerte.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">part</a></p>
                      <p className="descriptionType">
                        Generar un vinculo entre los distintas estructuras que comparten el
                        mismo transactionId. Si un segundo servicio es llamado, 
                        el part de este coincide con el part
                        registrado en el nivel del primer servicio que realizó la llamada. 
                        De esta forma es posible generar la trazabilidad 
                        completa y registrar cada llamado desde el paso exacto que fue realizado.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">artifact</a></p>
                      <p className="descriptionType">
                        Nombre del artefacto que genera la estructura.
                        Se trata de un valor lógico que es asignado el el archivo de propiedades, 
                        específicamente el valor "trackia.artifact".
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">status</a></p>
                      <p className="descriptionType">
                        Indica el resultado del proceso, 
                        sus valor posible puede ser <b>OK</b> o <b>NOK</b>.
                        Cada paso de un artefacto contiene su propio status.
                      </p>

                      <p><a className="classType">Request[] </a> 
                      <a className="valueType">request</a></p>
                      <p className="descriptionType">
                        Un arreglo con los parámetros de entrada.
                      </p>

                      <p><a className="classType">Object </a> 
                      <a className="valueType">response</a></p>
                      <p className="descriptionType">Objeto de respuesta del proceso.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">step</a></p>
                      <p className="descriptionType">Contiene un nombre lógico del proceso. Este valor puede ser incluido como información adicional para análisis de dato. </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">packageName</a></p>
                      <p className="descriptionType">Nombre del package que originó el proceso.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">method</a></p>
                      <p className="descriptionType">Nombre del método que originó el proceso.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">returnType</a></p>
                      <p className="descriptionType">Nombre de la clase que fue retornada en el método.</p>

                      <p><a className="classType">Date </a> 
                      <a className="valueType">transactionStart</a></p>
                      <p className="descriptionType">Fecha/hora en la que se ejecutó la operación.</p>

                      <p><a className="classType">Date </a> 
                      <a className="valueType">transactionEnd</a></p>
                      <p className="descriptionType">Fecha/hora en la que terminó la operación.</p>

                      <p><a className="classType">Long </a> 
                      <a className="valueType">duration</a></p>
                      <p className="descriptionType">Duración del método en milisegundo. Un valor numérico que se calcula: <b>transactionEnd</b> -  <b>transactionStart</b></p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">topic</a></p>
                      <p className="descriptionType">Nombre del tópico o cola que generó la estructura.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">url</a></p>
                      <p className="descriptionType">Url que se ejecutó.</p>

                      <p><a className="classType">List {"<Object>"} </a> 
                      <a className="valueType">info</a></p>
                      <p className="descriptionType">
                        Un arreglo que permite almacenar cualquier tipo de 
                        información.  Para agregar algún objeto pude utilizar 
                        <a className="classType"> journal.addInfo </a>("Por aquí pasé!!"), puede agregar 
                        cualquier tipo de objeto que aporte información al paso ejecutado.
                      </p>

                      <p><a className="classType">List {"<Journal>"} </a> 
                      <a className="valueType">children</a></p>
                      <p className="descriptionType">
                        Un arreglo de Track que contiene niveles 
                        internos de un proceso, por ejemplo, el Controller contiene 
                        un children con un Service.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">stereotype</a></p>
                      <p className="descriptionType">
                        Permite registrar categorías para ser consultadas en el futuro.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">description</a></p>
                      <p className="descriptionType">
                        Genera la descripción del paso, está información es utilizada generar
                        los diagramas, informes, etc.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">requestMethod</a></p>
                      <p className="descriptionType">
                        Indica el verbo HTTP que fue ejecutado 
                        para llamar el servicio.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">host</a></p>
                      <p className="descriptionType">
                        Indica el nombre del servidor donde está instalado el servicio.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">contextPath</a></p>
                      <p className="descriptionType">Especifica el contextPath del servicio.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">restMethod</a></p>
                      <p className="descriptionType">Endpoint del servicio.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">job</a></p>
                      <p className="descriptionType">Especifica el ID del job al que pertenece el registro.</p>

                      <p><a className="classType">Boolean </a> 
                      <a className="valueType">connectivityError</a></p>
                      <p className="descriptionType">
                        Este valor será <b>verdadero</b> si el error es producido por un problema de conectibidad.
                      </p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">type</a></p>
                      <p className="descriptionType">Indica el tipo de artefacto que generó el registro.</p>

                      <p><a className="classType">String </a> 
                      <a className="valueType">protocol</a></p>
                      <p className="descriptionType">Permite especificar el protocolo http del registro.</p>

                      <p><a className="classType">HashMap {"<String, Object>"} </a>
                      <a className="valueType">modules</a></p>
                      <p className="descriptionType">
                        Arreglo de extensiones. 
                        Para más información consulte la sección de <a href="/documentation/modules">Módulos </a> 
                        de la documentación
                      </p>

                      

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>);
}