import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';

function createValue(context, name) {
    return (context && context.account && context.account.street &&  context.account.street[name]) 
        ? context.account.street[name] 
        : "";
}

export default function AdressForm(theContext) {
    const [context, setContext] = React.useState(theContext);

    const [address1, setAddress1] = React.useState(createValue(theContext, "address1"));
    const [address2, setAddress2] = React.useState(createValue(theContext, "address2"));
    const [city, setCity] = React.useState(createValue(theContext, "city"));
    const [state, setState] = React.useState(createValue(theContext, "state"));
    const [zip, setZip] = React.useState(createValue(theContext, "zip"));
    const [country, setCountry] = React.useState(createValue(theContext, "country"));

    const [classText, setClassText] = React.useState(theContext.classText);
    

    const setValueForm = (field, val) => {
        let theContext = context;

        theContext.account.street[field] = val;
        setContext(theContext);

        let localClassText = classText;
        localClassText[field] = !val || val == "" ?"blink" :"";

        setClassText(localClassText);
      }

    return(
        <React.Fragment>
<>
<Grid item xs={12}>
          <TextField
            required
            id="address1"
            value={address1}
            className={classText["address1"]}
            onChange={event => {setValueForm("address1", event.target.value); setAddress1(event.target.value);}} 
            name="address1"
            label="Address line 1"
            fullWidth
            variant="standard"
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            id="address2"
            value={address2}
            onChange={event => {setValueForm("address2", event.target.value); setAddress2(event.target.value);}} 
            name="address2"
            label="Address line 2"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            value={city}
            className={classText["city"]}
            onChange={event => {setValueForm("city", event.target.value); setCity(event.target.value);}} 
            name="city"
            label="City"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="state"
            value={state}
            className={classText["state"]}
            onChange={event => {setValueForm("state", event.target.value); setState(event.target.value);}} 
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="zip"
            value={zip}
            onChange={event => {setValueForm("zip", event.target.value); setZip(event.target.value);}} 
            name="zip"
            label="Zip / Postal code"
            fullWidth
             variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            value={country}
            className={classText["country"]}
            onChange={event => {setValueForm("country", event.target.value); setCountry(event.target.value);}} 
            name="country"
            label="Country"
            fullWidth
             variant="standard"
          />
        </Grid>
      </>

    </React.Fragment>

    )
}