import React, { useState } from "react";
import { DataGrid} from '@mui/x-data-grid';
import {BsSearch} from "react-icons/bs";

export function ObjectInspectorGrid (data) {

    function filter (event){
      let inputGrid = document.getElementById("filterGrid");

      let filterGrid = inputGrid.value.toUpperCase();

      const rows = [];
      createRows(rows, data.data, data.data.duration, filterGrid);
      setDataGrid(rows);
    }

    function createRows(rows, step, total, filterGrid){
        let childrenDuration = 0;
        for(var i=0;i<step.children.length; i++){
            createRows(rows, step.children[i],total,filterGrid);
            childrenDuration = childrenDuration + step.children[i].duration;
        }

        let transactionStart = new Date(step.transactionStart);
        let dateStart = transactionStart.getHours() + ":" + transactionStart.getMinutes() + ":" + transactionStart.getSeconds() + "." + transactionStart.getMilliseconds();
        if(filterGrid == "" 
            || step.method.toUpperCase().indexOf(filterGrid) > -1
            || (step.packageName && step.packageName.toUpperCase().indexOf(filterGrid) > -1)
            || (step.url && step.url.toUpperCase().indexOf(filterGrid) > -1))
            
          {
          rows.push({
              id: rows.length+1
              ,status: step.status
              ,dateStart: dateStart
              ,method: step.method.split(":")[1]
              ,class: step.packageName
              ,durationRelative: step.duration - childrenDuration
              ,duration: step.duration
              ,durationParte: (((step.duration - childrenDuration) * 100 / total).toFixed(2))
              ,url: step.url
              ,isFinal: (!step.children || step.children.length == 0)
          });
        }

    }

    const columns = [
        { field: 'status', headerName: 'Status', width: 70 },
        { field: 'dateStart', headerName: 'Date Start', width: 110},
        { field: 'duration', headerName: 'Duration Total', type: 'number', width: 110},
        { field: 'durationRelative', headerName: 'Duration Relative', type: 'number', width: 160},
        { field: 'durationParte', headerName: 'Duration %', type: 'number', width: 160},
        { field: 'class', headerName: 'Class Name', width: 560 },
        { field: 'method', headerName: 'Method', width: 200 },
        { field: 'isFinal', headerName: 'Final Step', type: 'boolean', width: 90 },
        { field: 'url', headerName: 'Url', width: 1500 },
      ];

      
      const rows = [];
      createRows(rows, data.data, data.data.duration, "");
      const [dataGrid, setDataGrid] = useState(rows);


    return (
      <>
        <BsSearch className="gridSearch"></BsSearch>
        <input id="filterGrid" type="text" onKeyDown={filter} placeholder="Filter" autoComplete="off"/>
        
        
        <div className="gridTable" style={{ height: 'calc(100% - 80px)', width: '100%' }}>
          <DataGrid
            rows={dataGrid}
            columns={columns}
         />
        </div>
      </>
      );
}
