import * as React from 'react';
import { Box, Button, Container, Fab, Paper, Typography } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { HeaderZone } from '../../HeaderZone';

import PrincipalForm from './PrincipalForm';
import AdressForm from './AdressForm';
import Service from '../../../helpers/Service';
import './UpdateAccount.css';

import AlertIcon from '@mui/icons-material/Warning';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default class UpdateAccount extends React.Component{
  constructor(props) {
    super(props);
    this.service = new Service();

    this.state = {
      value: 0,
      valid: ["companyName", "address1", "city", "state", "country"],
      context: {},
      classText: {},
      principalTabIcon: {display: "none"},
      addresTabIcon: {display: "none"}
    }
  }

  componentDidMount() {
    this.loadAccount();
  }

  async loadAccount(){
    let account = await this.service.getAccount();
    this.setState({context: account})
  }

  onUpdateContext(me, context){
    me.setState({context: context.account})
  }

   handleChange (event, newValue) {
    this.setState({value: newValue})
  };



  async save () {
    let valid = await this.isValid();
    if(valid){
      this.service.updateAccount(this.state.context);
    }
  }

  async isValid() {
    let isValid = true;
    let theContext = this.state.context;

    this.setState({principalTabIcon: {display: "none"}});
    if(!theContext.name){
      this.state.classText["name"] = "blink";
      this.setState({principalTabIcon: {display: "block"}});
      isValid = false;
    }

    this.setState({addresTabIcon: {display: "none"}});
    if(!theContext.street.address1){
      this.state.classText["address1"] = "blink";
      this.setState({addresTabIcon: {display: "block"}});
      isValid = false;
    }

    if(!theContext.street.city){
      this.state.classText["city"] = "blink";
      this.setState({addresTabIcon: {display: "block"}});
      isValid = false;
    }

    if(!theContext.street.state){
      this.state.classText["state"] = "blink";
      this.setState({addresTabIcon: {display: "block"}});
      isValid = false;
    }

    if(!theContext.street.country){
      this.state.classText["country"] = "blink";
      this.setState({addresTabIcon: {display: "block"}});
      isValid = false;
    }

    this.setState({context: theContext})
    return isValid;
  }


  render() {
    return (
      <React.Fragment>
          <HeaderZone iniciar={true}></HeaderZone>

          <Container className='create-account-home'  >
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              
              <Typography component="h1" variant="h4" align="center">
                Account Company 
              </Typography>

                <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.value} 
                            onChange={(event, newValue) => this.handleChange(event, newValue)} 
                            aria-label="basic tabs example">
                          <Tab label={<div>Principal<div className='boxTabMesage blink' style={this.state.principalTabIcon} ><AlertIcon fontSize='5px'></AlertIcon></div></div>} {...a11yProps(0)} />
                          <Tab label={<div>Address<div className='boxTabMesage blink' style={this.state.addresTabIcon}><AlertIcon fontSize='5px'></AlertIcon></div></div>} {...a11yProps(1)} />
                        </Tabs>
                      </Box>

                      {(this.state.context.id ?
                        <div>
                          <CustomTabPanel value={this.state.value} index={0}>
                            <PrincipalForm account={this.state.context} classText={this.state.classText} onUpdateContext={(context) => {this.onUpdateContext(this, context)}} > </PrincipalForm>
                          </CustomTabPanel>
                          <CustomTabPanel value={this.state.value} index={1}>
                            <AdressForm account={this.state.context} classText={this.state.classText} onUpdateContext={this.onUpdateContext}></AdressForm>
                          </CustomTabPanel>
                        </div>
                        :<></>
                      )}

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                  <Button
                            variant="contained"
                            onClick={() => {this.save()}}
                            sx={{ mt: 3, ml: 1 }}
                          >
                            Save
                  </Button>
                  </Box>

            </Paper>
            
          </Container>
        </React.Fragment>
    );
  }
}