import React, { useState } from 'react';
import { Button } from '@mui/material';
import { E2E } from '../../E2E/E2E';

import '../../E2E/index.css';

export function ButonsGui({leccionIndex, setContext, setLeccionIndex}) {
    const MAX_LECCION = 7;
    function setBoxStyle(current){
        if(current > 4 ){
            setContext({'doc': true, 'menuTransacciones': 'OpacityElement'});
        }else{
            setContext({'doc': true, 'menuTransacciones': ''});
        }
    }

    return (<>
            <Button className="anterior"  disabled={leccionIndex == 0?"true":""}  
                    onClick={() => { 
                    if(leccionIndex > 0){
                        setLeccionIndex(leccionIndex - 1);
                        let current = leccionIndex-1;
                        setBoxStyle(current);
                    }
                }
            }>Anterior</Button>
            <Button className="siguiente" disabled={leccionIndex == MAX_LECCION?"true":""} 
                    onClick={() => { 
                        if(leccionIndex < MAX_LECCION){
                            setLeccionIndex(leccionIndex + 1);
                            let current = leccionIndex+1;
                            setBoxStyle(current);
                        } 
                        
            }}>Siguiente</Button>
    
    </>);
}

export function E2eGui() {
    const [leccionIndex, setLeccionIndex] = useState(0);
    const [context, setContext] = useState({
        'doc': true, 
        'menuTransacciones': ''
    });
    
    return (
        <>
            <div className="E2EGUI">
                <E2E doc={context} ></E2E>
            </div>

            <div className='seccionBox'>

                <div className='BusquesaSeccion' style={{'width': '650px', 'height': '280px', 'display': leccionIndex == 0 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>E2E</h2>
                    <p>Proporciona una vista de extremo a extremo de la transacción.
                    Esta dividida en las siguientes secciones, en la parte izquierda: </p>
                    <li>Búsqueda y Selección</li>
                    <li>Vistas E2E.</li>
                </div>


                <div className='BusquesaSeccion' style={{'width': '650px', 'height': '200px', 'display': leccionIndex == 1 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Búsqueda y Selección</h2>
                    <p>Esta es la zona de búsqueda y selección.</p>
                </div>

                <div className='MarcoMuestra' style={{'left':'445px', 'width': '510px', 'height': 'calc(100% - 560px)', 'display': leccionIndex == 1 ? 'block' : 'none' }}>
                </div>


                <div className='BusquesaSeccion' style={{ 'width': '350px', 'display': leccionIndex == 2 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Búsqueda y Selección</h2>
                    <p>Prueba buscar <b>123</b> y <b>124</b>.</p>
                </div>

                <div className='MarcoMuestra' style={{'left':'445px', 'width': '510px', 'height': '50px', 'display': leccionIndex == 2 ? 'block' : 'none' }}>
                </div>



                <div className='BusquesaSeccion' style={{ 'width': '350px', 'display': leccionIndex == 3 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Búsqueda y Selección</h2>
                    <p>Prueba buscar <b>123</b> y <b>124</b>.</p>
                    <p>Perfecto, has completado esta lección.</p>
                    
                </div>


                <div className='BusquesaSeccion' style={{ 'width': '500px', 'display': leccionIndex == 4 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Búsqueda y Selección</h2>
                    <p>
                        Si buscas una transacción,
                        estará en tu sesión local mientras no la borres, 
                        esto significa que la puedes <b>seleccionar</b> aun si estas desconectado.
                    </p>
                </div>

                <div className='MarcoMuestra' style={{'marginTop': '40px', 'left':'445px', 'width': '510px', 'height': '350px', 'display': leccionIndex == 4 ? 'block' : 'none' }}>
                </div>


                <div className='BusquesaSeccion' style={{ 'width': '370px', 'left': '500px', 'display': leccionIndex == 5 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Vistas E2E</h2>
                    <p>Veamos ahora que hay en la vista E2E. En esta zona encontraras un diagrama.</p>
                </div>

                <div className='MarcoMuestra' style={{'marginTop': '-20px', 'left':'940px', 'width': '970px', 'height': '430px', 'display': leccionIndex == 5 ? 'block' : 'none' }}>
                </div>


                <div className='BusquesaSeccion' style={{ 'width': '370px', 'left': '500px', 'display': leccionIndex == 6 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Vistas E2E</h2>
                    <p>Este icono te permite seleccionar el vista que quieres visualizar, las opciones disponibles son:</p>
                    <li>Artifact Diagram</li>
                    <li>Secuence Diagram</li>
                    <li>AST Struct</li>
                    <li>PSeudo Code</li>
                </div>

                <div className='MarcoMuestra' style={{'marginTop': '-50px', 'left':'calc(100% - 100px)', 'width': '70px', 'height': '40px', 'display': leccionIndex == 6 ? 'block' : 'none' }}>
                </div>

                <div className='BusquesaSeccion' style={{ 'width': '370px', 'left': '500px', 'display': leccionIndex == 7 ? 'block' : 'none' }}>
                    <ButonsGui 
                        setLeccionIndex={setLeccionIndex}
                        leccionIndex={leccionIndex}
                        setContext={setContext}
                    />
                    <h2>Vistas E2E</h2>
                    <p>Cambia la vista a: <b>"Secuence Diagram"</b></p>
                </div>

            </div>
        </>);
}
