import React from 'react';
import { HeaderZone } from "../HeaderZone";
import './index.css';
import "react-multi-carousel/lib/styles.css";

export function Home() {
    return (
      <>
        <div className="contenedor-principal">
        <HeaderZone iniciar={true}/>
          <div className="inicio">

            <div className="center-text">
                <h1>
                  Trazabilidad E2E, <br/>
                  en arquitecturas de microservicios<br/>
                  basados en springboot.
                </h1>

            <div >
                <div className="box">
                  <p>En una arquitectura orientada a micro servicios 
                  suele haber al menos un LOG por cada componente desplegado.
                  Esto genera una alta dificultad a la hora de solucionar incidente, 
                  ya que la traza esta repartida por múltiples orígenes.
                  </p>
                  
                  <p>
                  TrackIA recolecta la información necesaria para generar la traza 
                  con apenas unas pocas líneas de código.
                  </p>
                </div>

            <h2>¿Comó funciona? </h2>
              <div className="box">
                <p>
                  Utiliza el paradigma del AOP, en vez de las tradicionales técnicas basadas análisis de log,<br/>
                  este enfoque permite generar muchísima mas información a un menor costo.
                </p>
              </div>


            <h2>Trasabilidad de extremo a extremo.</h2>
              <p>
                La información recopilada permite identificar
                el punto exacto donde ocurrio un error.
              </p>

              <p>
                El reporte comienza con una vista general de la trasacción 
                hasta llegar al punto exacto donde ocurrio la falla. 
                
                Un viaje que incluye información 
                clave como nombres de clases, metodos y variables.
              </p>

              <img className="imagen" alt="helloWorld Example" src="../../img/helloWorld.png" ></img>

            <h2>El diseño de un reporte comienza en el programa.</h2>
                <p>
                  La mayoría de los sistemas de informe que ofrecen observabilidad de extremo a extremo<br/> 
                  lo realizan a partir de la infraestructura. Este enfoque <br/>
                  aumenta considerablemente el costo a la hora de levantar un dashboard o peor aun "resolver un incidente". <br/>
                  Esto ocurre ya que existe un quiebre entre el sistema e informe.
                </p>

                <p>
                  La propuesta de TrackIA es diferente, <br/>
                  comenzar el diseño del reporte en el código fuente, <br/>
                  lo que garantiza reportes coherentes.
                </p>

            </div>
            </div>
          </div>

        </div>
      </>
    );
  }