import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Artifacts(){
    return (
        <>


<div className="contenedor-documentacion"></div>
    <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">
                    <h1>Artefactos</h1>

                    <p>
                    Esta sección, describe como configurar correctamente un artefacto 
                    y explica que significa cada campo.
                    </p>

                    <p>
                    Los artefactos son representaciones lógicas de un <i>servicio</i>, <i>kafka</i>, <i>job</i> u <i>otro artefacto </i> 
                    del sistema.
                    A partir de esta representación, se controla el comportamientos de trackia al momento 
                    de generar la <a href="/documentation/struct">estructura.</a>
                    </p>

                    <p>Cuando se crea un artefacto, se asigna automáticamente un <b>APIKEY</b> para 
                    ser referenciado. Este valor es utilizado en el archivo de propiedades, 
                    en el valor: <b>trackia.dispatcher.apiKey</b>
                    </p>

                    <h2>¿Como crear un artefacto?</h2>
                    <p>
                        En la parte superior derecha, existe un icono redondo que contiene la primera letra 
                        del nombre de la cuenta. Al realizar click sobre este icono, se abre un menú con algunas opciones.
                        Seleccionar la opción <a href="/admin/artifacts">Manage Artifacts</a>.
                    </p>

                    <p>
                        Una vez dentro de "Manage Artifacts" se presenta una interfase que se divide en 2 secciones.
                        En la parte izquierda se muestran una lista con los artefactos creados y en la parte derecha 
                        un formulario con las propiedades del artefacto.
                    </p>


                    <h3>Lista de pasos para crear un artefacto:</h3>
                    <li><b>Paso 1</b>: Presionar el icono (+) que se ubica en la parte inferior del listado de a artefactos.</li>
                    <li><b>Paso 2</b>: Completar el formulario con los valores.</li>
                    <li><b>Paso 3</b>: Presionar el botón enviar ({">"}) en la parte superior del formulario, este paso 
                    guarda los cambios.</li>

                    
                    <h3>Significado de cada campo</h3>
                    <p>En este sección, se describe que significa cada campo y los valores posibles que son aceptados:</p>
                    <li>
                        <b>Name</b>: Indica el nombre del artefacto, puede ser cualquier valor alfanumérico, 
                        pero se recomienza que coincida con el valor <b>artifactId</b> del pom.xml del artefacto físico 
                        que se desea trackear.
                    </li>
                
                    <li>
                        <b>Description</b>: Una breve descripción del artefacto que se documenta.
                        Se recomienda que este valor coincida con el valor <b>description</b> del pom.xml
                    </li>

                    <li>
                        <b>Min Deley</b>: Este valor indica un tiempo mínimo de espera entre cada artefacto almacenado, 
                        Si un artefacto físico enviá un track solo se almacenara un segundo track cuando haya transcurrido el tiempo
                        que este valor indique, en caso que no haya pasado este tiempo, el track será descartado y continuara 
                        con el flujo normal del sistema.
                    </li>

                    <li>
                        <b>Max transaction Day</b>: Establece la cantidad máxima de transacciones almacenadas en un día.
                        Cuando se supere este valor, el track será descartado y continuará el flujo normal del sistema.
                    </li>

                    <li>
                        <b>Host</b>: Indica la url del artefacto físico dentro de la infraestructura. Si este valor es alcanzable
                        desde Trackia Cloud, cada vez que se realice una modificación del artefacto lógico, se enviará una 
                        notificación del cambio.
                    </li>

                    <li>
                        <b>Type</b>: Indica el tipo de artefacto físico. Una clasificación que permite agrupar los 
                        distintos artefactos que se encuentran en la infraestructura. 
                        Cada cuenta es libre de indicar el valor que estime conveniente, pero se recomienda clasificarlos de la siguientes
                        valores: API, KAFKA o JOB.
                    </li>

                    <li>
                        <b>Request</b>: Establece las políticas de almacenado de parámetros dentro de un método. 
                        Los posibles valores son:
                        <br/>
                        <br/>
                        <p class="subLista"><b>all</b>: Almacena todos los parámetros de todos los métodos.</p>
                        <p class="subLista"><b>allRoot</b>: Almacena todos los parámetros del método inicial.</p>
                        <p class="subLista"><b>HTTP</b>: Almacena solo los parámetros  de los métodos que hagan llamadas http.</p>
                        <p class="subLista"><b>HTTP.GET</b>: Almacena solo los parámetros de los métodos que hagan llamadas http get.</p>
                        <p class="subLista"><b>HTTP.POST</b>: Almacena solo los parámetros de los métodos que hagan llamadas http post.</p>
                        <p class="subLista"><b>HTTP.PUT</b>: Almacena solo los parámetros de los métodos que hagan llamadas http put.</p>
                        <p class="subLista"><b>HTTP.DELETE</b>: Almacena solo los parámetros de los métodos que hagan llamadas http delete.</p>
                    </li>
                    <li>
                        <b>Response</b>: Establece las políticas de almacenado de parámetros de respuesta dentro de un método. 
                        Los posibles valores son:
                        <br/>
                        <br/>
                        <p class="subLista"><b>all</b>: Almacena todas las respuestas de todos los métodos.</p>
                        <p class="subLista"><b>allRoot</b>: Almacena todos las respuestas del método inicial.</p>
                        <p class="subLista"><b>HTTP</b>: Almacena todas las respuestas de los métodos que hagan llamadas http.</p>
                        <p class="subLista"><b>HTTP.GET</b>: Almacena solo las respuestas de los métodos que hagan llamadas http get.</p>
                        <p class="subLista"><b>HTTP.POST</b>: Almacena solo las respuestas de los métodos que hagan llamadas http post.</p>
                        <p class="subLista"><b>HTTP.PUT</b>: Almacena solo las respuestas de los métodos que hagan llamadas http put.</p>
                        <p class="subLista"><b>HTTP.DELETE</b>: Almacena solo las respuestas de los métodos que hagan llamadas http delete.</p>
                    </li>

                    <li>
                        <b>Modules</b>: Carga <a href="/documentation/modules">módulos</a> que pueden ser referenciados al ejecutar 
                        un track sobre un método. Existen 3 módulos nativos, pero pueden ser agregados nuevos de negocios.
                        En el siguiente ejemplo se cargan los 3 módulos nativos: sysInfoModule, slaModule, indexableModule.
                    </li>


                    <h2>¿Como modificar un artefacto?</h2>

                    <p>
                        <li><b>Paso 1</b>: Seleccionar el artefacto que se desea modificar desde la lista en la parte izquierda.</li>
                        <li><b>Paso 2</b>: Presionar el lápiz ubicado en la parte superior derecha, lo que habilitara los campos de entrada.</li>
                        <li><b>Paso 3</b>: Hacer los cambios en cuestión en la interfaz web.</li>
                        <li><b>Paso 4</b>: Presionar el botón enviar ({">"}) en la parte superior del formulario, este paso guarda los cambios.</li>

                    </p>

                </div>
            </div>
        </div>
     </>);
}