import React, { useRef, useState } from 'react';
import { HeaderZone } from '../../HeaderZone';
import './AdminUsers.css';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiArrowBack, BiPencil } from "react-icons/bi";
import { BiSend } from "react-icons/bi";

import { AiOutlinePlus } from "react-icons/ai";
import Service from '../../../helpers/Service';
import { Box, Button, Container, Paper, Switch, TextField, Typography } from '@mui/material';
import { Tab, Tabs } from 'react-tabs';
import AlertIcon from '@mui/icons-material/Warning';
import PrincipalForm from './PrincipalForm';
import AdressForm from './AdressForm';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default class AdminUsers extends React.Component{
    constructor(props) {
        super(props);
        this.service = new Service();

        let classText = {};
        classText["name"] = "";
        classText["description"] = "";

        this.state = {
            list: [],



            classText: classText,

            buttonAdd:{"display":"none"},
            buttonUpdate:{"display":"none"},
            iconUpdate:{"display":"none"},

            valid: ["companyName", "address1", "city", "state", "country"],
            context: {},
            principalTabIcon: {display: "none"},
            addresTabIcon: {display: "none"}

        };
    }

    add(){
        
    }

    componentDidMount() {
        this.load();
    }


    selectItem(selected){
    }

    back(){
    }

    async delete(id){
    }

    async load () {
        let self = this;

        let list = await this.service.getUsersAccount();
        self.setState({list: list});

    };

    handleChange(self){
        self.setState({tracking: !self.state.tracking});
    };

    render() {
        let self = this;

        return (
            <React.Fragment>
            
            <HeaderZone iniciar={true}></HeaderZone>
            <Container className='create-account-home'  >
            <div className="contenedor-documentacion">
                <div className="inicioMantenedor">
                    <div className='listaMantenedor' style={this.state.menu}>
                        {this.state.list.map(element => (
                            <div className='elementMantenedor hover' key={element.id}
                            onClick={() => {this.selectItem(element)}}>
                                <div className='avatar sesionSettingIcon radius-8cqb '>
                                    {element.firstName ?element.firstName.substring(0,1):""}
                                </div>
                                <p className="mantenedorText bold">{element.firstName + " " + element.lastName} </p>
                                <p className="mantenedorText">{element.email}</p>

                                <div className="eliminaMantenedor eliminaSelected" onClick={() => this.delete(element.id)}>
                                    <AiOutlineDelete size={20}  />
                                </div>
                                
                            </div>
                        ))} 

                        <div className='boxMenu radius-8cqb'>
                            <AiOutlinePlus className='addIcon' onClick={() => {this.add()}} size={30}></AiOutlinePlus>
                        </div>
                    </div>
                    <div className='backIcon' style={this.state.iconBack} onClick={() => {this.back()}}>
                        <BiArrowBack size={22}></BiArrowBack>
                    </div>
                </div>







            
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              
              <Typography component="h1" variant="h4" align="center">
                Account Company 
              </Typography>

                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.value} 
                            onChange={(event, newValue) => this.handleChange(event, newValue)} 
                            aria-label="basic tabs example">
                          <Tab label={<div>Principal<div className='boxTabMesage blink' style={this.state.principalTabIcon} ><AlertIcon fontSize='5px'></AlertIcon></div></div>} {...a11yProps(0)} />
                          <Tab label={<div>Address<div className='boxTabMesage blink' style={this.state.addresTabIcon}><AlertIcon fontSize='5px'></AlertIcon></div></div>} {...a11yProps(1)} />
                        </Tabs>
                      </Box>

                      {(true ?
                        <div>
                          <CustomTabPanel value={this.state.value} index={0}>
                            <PrincipalForm account={this.state.context} classText={this.state.classText} onUpdateContext={(context) => {this.onUpdateContext(this, context)}} > </PrincipalForm>
                          </CustomTabPanel>
                          <CustomTabPanel value={this.state.value} index={1}>
                            <AdressForm account={this.state.context} classText={this.state.classText} onUpdateContext={this.onUpdateContext}></AdressForm>
                          </CustomTabPanel>
                        </div>
                        :<></>
                      )}

                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                  <Button
                            variant="contained"
                            onClick={() => {this.save()}}
                            sx={{ mt: 3, ml: 1 }}
                          >
                            Save
                  </Button>
                  </Box>
                  
            </Paper>
            
 
             </div>
            </Container>
            </React.Fragment>
        );
    }
}