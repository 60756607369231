import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from "./error-page";

import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import { E2E } from './components/E2E/E2E';
import { Home } from './components/Home/Home';
import { InicioRapido } from './components/Documentation/InicioRapido';
import { Struct } from './components/Documentation/Struct';
import { Dispatcher } from './components/Documentation/Dispatcher';
import { Modulos } from './components/Documentation/Modulos';
import { Anotaciones } from './components/Documentation/Anotaciones';
import { Ejemplos } from './components/Documentation/Ejemplos';
import { Install } from './components/Documentation/Install';
import AdminArtifacts from './components/Account/AdminArtifacts';
import Login from './components/Account/Login';
import CreateAccount from './components/Account/create/CreateAccount';
import UpdateAccount from './components/Account/update/UpdateAccount';
import AdminUsers from './components/Account/usuarios/AdminUsers';
import Sumary from './components/Sumary/Sumary';
import { Artifacts } from './components/Documentation/Artifacts';
import { Prices } from './components/Documentation/Prices';
import { GuiIntroduction } from './components/Documentation/gui';


const router = createBrowserRouter([
  {errorElement: <ErrorPage />},
  {path: "/",              element: <Home />},
  {path: "/signin",       element: <Login />},
  {path: "/account/create",      element: <CreateAccount />},
  {path: "/account/update",      element: <UpdateAccount />},

  {path: "/documentation", element: <InicioRapido />},
  {path: "/documentation/quickstart",  element: <InicioRapido header='true' />},
  {path: "/documentation/struct",     element: <Struct header='true' />},
  {path: "/documentation/artifacts",     element: <Artifacts header='true' />},
  {path: "/documentation/dispatcher",    element: <Dispatcher header='true' />},
  {path: "/documentation/modules",       element: <Modulos header='true' />},
  {path: "/documentation/anotations",   element: <Anotaciones header='true' />},
  {path: "/documentation/examples",      element: <Ejemplos header='true' />},

  {path: "/documentation/gui/index",      element: <GuiIntroduction header='true' />},
  

  {path: "/documentation/prices",   element: <Prices header='true' />},
  {path: "/documentation/install",   element: <Install header='true' />},
  {path: "/admin/artifacts",   element: <AdminArtifacts header='true' />},
  {path: "/admin/usuarios",   element: <AdminUsers header='true' />},
  
  
  
  {path: "/summary",      element: <Sumary />},
  
  {path: "/E2E",                         element: <E2E />},
  
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);