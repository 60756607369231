import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Ejemplos(){
    return (
        <>

<div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
              <div className="documentacionTrackia">
                <MenuDocumentacion />
                <div className="doc-contenido">



                        <h1 id="ejemplos">Ejemplos</h1>
                <p>Utilice 'Hello World' o 'Calculator' ejemplos como punto de partida 
                  de proyectos trackeados, desde ahí puede comprender el mecanismo y flujo.</p>

                <h4>Hello World</h4>
                <p>Comencemos con el tradicional Hello World, este ejemplo responde "Hello World, my name is @Trackia"</p>
                <pre className="pre">{`
 GET http://localhost:5555/helloWorld
 {  
  "type": "local"
 }
 `}</pre>
                <p>El parámetro <b>type</b> puede ser:</p>
                
                <p><a className="valueType">local</a></p>
                <p className="descriptionType">
                  Ejecuta el saludo de manera autónomo, es decir, toda la transacción ocurre dentro de un solo servicio. Ilustra el funcionamiento de trackia en su manera básica.
                </p>

                <p><a className="valueType">service</a></p>
                <p className="descriptionType">
                Ejecuta un segundo servicio (my-name-is-demo), para completar el saludo. 
                Ilustra la ejecución de 2 servicios dentro de la misma transacción, para
                completar el caso de exito, 
                debes tener ambos servicios ejecutados.
                </p>

                <p className="descriptionType">
                  También puedes probar este parámetro y solo levantar el 
                  primer servicio (hello-world-demo), lo que produce un error de conectividad y
                  revisar que ocurre cuando se ejecuta este caso.
                </p>

                <p><a className="valueType">error</a></p>
                <p className="descriptionType">
                Lanza una excepción. Este parámetro ilustra como se visualizan los 
                errores.
                </p>

                <h2>Gitlab Fuentes</h2>
                  <p><a target='hello-world-demo' href='https://github.com/trackiaapp/hello-world-demo'>hello-world-demo</a></p>
                  <p><a target='my-name-is-demo' href='https://github.com/trackiaapp/my-name-is-demo'>my-name-is-demo</a></p>


                  <h4>Calculator</h4>
                <p>
                  El segundo ejemplo que tenemos preparado, es la
                  típica calculadora, consiste en 
                  4 servicios que interactúan para realizar el cálculo: 
                  addition, subtraction, multiplication y division.

                  La entrada condiciona el flujo de los servicios 
                  ejecutados, lo que lo hace ideal para comprender una transaccion 
                  compuesta por múltiples artefactos. Pruebe la división por cero 
                  y otras condiciones e borde.
                </p>

                <pre className="pre">{`
 POST http://localhost:5557/addition
 {
  "left": {
      "operation" : "*",
      "left": "10",
      "right": {
          "operation" : "/",
          "left": "10",
          "right": "10"
      }
  },
  "right": {
      "operation" : "-",
      "left": "10",
      "right": "10"
  }
}
 
 `}</pre>

                <h2>Gitlab Fuentes</h2>
                  <p><a target='addition' href='https://github.com/trackiaapp/calc-addition'>addition</a></p>
                  <p><a target='subtraction' href='https://github.com/trackiaapp/calc-subtraction'>subtraction</a></p>
                  <p><a target='multiplication' href='https://github.com/trackiaapp/calc-multiplication'>multiplication</a></p>
                  <p><a target='division' href='https://github.com/trackiaapp/calc-division'>addition</a></p>
                  </div>
            </div>
            </div>
        </div>
     </>);
}