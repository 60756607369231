import { createContext } from "react";

const AccountContext = createContext({
     firstName: ''
    ,lastName: ''
    ,phone: ''
    ,email: ''
    ,password: ''
    ,repeat: ''

    ,companyName: ''
    ,companyDescription: ''
    ,webSite: ''
    
    ,address1: ''
    ,address2: ''
    ,city: ''
    ,state: ''
    ,zip: ''
    ,country: ''
    
});

export default AccountContext;