import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import AccountContext from './AccountContext';

function createValue(context, name) {
  return (context && context.account && context.account[name]) 
      ? context.account[name] 
      : "";
}

export default function PrincipalForm(theContext) {
    const [context, setContext] = React.useState(theContext);

    const [name, setName] = React.useState(createValue(theContext, "name"));
    const [description, setDescription] = React.useState(createValue(theContext, "description"));
    const [website, setWebsite] = React.useState(createValue(theContext, "website"));

    const [classText, setClassText] = React.useState(theContext.classText);
    
    const setValueForm = (field, val) => {
        let theContext = context;
        theContext.account[field] = val;
        setContext(theContext);
        
        let localClassText = classText;
        localClassText[field] = !val || val == "" ?"blink" :"";

        setClassText(localClassText);
        context.onUpdateContext(theContext);
      }

      


    return(
        <React.Fragment>
<>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            value={name}
            className={classText["name"]}
            onChange={event => {setValueForm("name", event.target.value); setName(event.target.value)}} 
            name='name'
            label="Name"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="description"
            value={description}
            onChange={event => {setValueForm("description", event.target.value); setDescription(event.target.value)}} 
            name='description'
            label="Description"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="website"
            value={website}
            onChange={event => {setValueForm("website", event.target.value); setWebsite(event.target.value)}} 
            name='website'
            label="Web Site"
            fullWidth
            variant="standard"
          />
        </Grid>

      </Grid>
      </>

    </React.Fragment>

    )
}