import React, { useState } from 'react';
import './documentation.css';
import { AiOutlineMenu } from "react-icons/ai";

export function MenuDocumentacion(){
    let actual = window.location.href;
    const [menuIcon, setMenuIcon] = useState({});

    function menu(obj){
        if(menuIcon.display === "block"){
            setMenuIcon({"display": "none"});
        }else{
            setMenuIcon({"display": "block"});
        }
    }


    return (
        <>
            <div className="menu-icon"  onClick={() => menu(this)} >
                <AiOutlineMenu size={22}  ></AiOutlineMenu>
            </div>

            

            <div className="doc-menu" style={menuIcon}>
                <ul> 
                  <li aria-expanded="false"><span className={actual.endsWith('/quickstart') || actual.endsWith('documentation') ?'selected' :''}><a href="/documentation/quickstart">Quick Start</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/examples') ?'selected' :''}><a href="/documentation/examples">Example</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/struct') ?'selected' :''}><a href="/documentation/struct">Struct</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/artifacts') ?'selected' :''}><a href="/documentation/artifacts">Manage Artifacts</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/dispatcher') ?'selected' :''}><a href="/documentation/dispatcher">Dispatcher</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/modules') ?'selected' :''}><a href="/documentation/modules">Modules</a></span></li> 


                  <li aria-expanded="false"><span className={actual.endsWith('/anotations') ?'selected' :''}><a href="/documentation/anotations">@Trackia</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/prices') ?'selected' :''}><a href="/documentation/prices">Prices</a></span></li> 
                  <li aria-expanded="false"><span className={actual.endsWith('/install') ?'selected' :''}><a href="/documentation/install">Install</a></span></li> 
                </ul>
            </div> 
        </>
    );
}

