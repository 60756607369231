import React, { useState } from 'react';
import './../documentation.css';
import { HeaderZone } from '../../HeaderZone';
import { MenuDocumentacion } from './../MenuDocumentacion';
import { E2eGui } from './E2eGui';

export function GuiIntroduction(){

    const [selected, setSelected] = useState("/");

    function onSelect (e, obj){
        setSelected(e);
    }




    return (
        <>
        <div className="contenedor-documentacion">
            <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
                <div className="documentacionTrackia">
                    <MenuDocumentacion />
                    <div className="doc-contenido">
                        <h1 id="Trackia">Interfaz gráfica de usuario</h1>
                        <p>
                        En esta sección encontrará información útil de la interfaz gráfica de usuario para sacar mayor 
                        provecho de las funcionalidades disponibles.
                        </p>

                        <h4>Menu</h4>
                        <p>
                            Para comenzar, se describe el menú que lanza las principales opciones disponibles:
                        </p>


                        <div class="docuMenuExample">
                            <HeaderZone iniciar={true} doc={true} onSelect={onSelect}/>
                        </div>

                        <div className='docuInfo'>
                            
                            <div style={{"display": selected == "/" ?"block" :"none"}}>
                                <h2>Home</h2>
                                <p>
                                    En el home encontraras la presentación del proyecto, una simple página de inicio que explica los fundamentos de Trackia.
                                </p>
                            </div>


                            <div style={{"display": selected == "/documentation" ?"block" :"none"}}>
                                <h2>Documentation</h2>
                                <p>
                                    Usted ya está en la documentación, en esta sección encontrara toda la 
                                    información que tenemos disponible.
                                </p>

                                <p>
                                    La documentación tiene una pequeña diferencia entre la versión movil y 
                                    escritorio, donde en versiones moviles oculta los accesos directos y 
                                    los presenta al precionar el icono de amburgueza en la parte izquierda
                                    del menú, mientras que en la version de escritorio presenta el menú 
                                    directamente.
                                </p>
                            </div>

                            <div style={{"display": selected == "/E2E" ?"block" :"none"}}>

                                <E2eGui></E2eGui>
                                
                            </div>

                            <div style={{"display": selected == "/summary" ?"block" :"none"}}>
                                <h2>Sumary</h2>
                                <p>
                                    Ejecutar consultas de transacciones por rango de fecha.
                                    Presenta un contador de existos y fracasos por artefacto.
                                </p>

                                <img className="imagen" alt="Sumary" src="/img/gui/summary.png" ></img>
                                
                                <p>
                                    Si desea consultar el detalle de un estado para un artefacto, 
                                    pueden realizar
                                    click sobre estado (exito o fracaso) y se abrirá una <b>lista con todas las trasacciones</b> del 
                                    artefacto en ese estado seleccionado (LDA).
                                </p>

                                <img className="imagen" alt="Sumary" src="/img/gui/lda.png" ></img>

                                <p>
                                    Se puede ordenar el LDA por <b>fecha de creación</b> o <b>duración</b>, 
                                    se pagina por lotes de 100 registros.
                                    Si hace click sobre el botón E2E de un registro del LDA, mostrara a opción E2E,
                                    donde encontrará las vistas de la trasacción.
                                </p>
                            </div>

                            <div style={{"display": selected == "/documentation/install" ?"block" :"none"}}>
                                <h2>Donwload</h2>
                                <p>
                                    En esta sección encontrará información útil de la interfaz gráfica de usuario para sacar mayor provecho de las funcionalidades disponibles.
                                </p>
                            </div>

                            <div style={{"display": selected == "/user" ?"block" :"none"}}>
                                <h2>Account</h2>
                                    
                                <li>You account id is</li>
                                <li>Corporate Account</li>
                                <li>Manage Artifacts</li>
                                <li>Sign off</li>
                            
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
