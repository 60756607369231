import React, { useContext} from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { HeaderZone } from '../../HeaderZone';
import UserForm from './UserForm';
import CompanyForm from './CompanyForm';
import AddressForm from './AddressForm';
import AccountContext from "./AccountContext";

import './CreateAccount.css';
import Service from "../../../helpers/Service";
import { redirect } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://trackia.app/">
        Trackia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['User Root', 'Company', 'Address'];

function getStepContent(step, setActiveStep) {
  switch (step) {
    case 0:
      return <UserForm setActiveStep={setActiveStep} />;
    case 1:
      return <CompanyForm setActiveStep={setActiveStep}/>;
    case 2:
      return <AddressForm setActiveStep={setActiveStep}/>;
    default:
      throw new Error('Unknown step');
  
  }
}

export default function CreateAccount(){
  const [activeStep, setActiveStep] = React.useState(0);
  const accountContext = React.createContext(AccountContext);
  const context = React.useContext(accountContext);
  let service = new Service();
  if(!service.getToken()){

    return (
      <React.Fragment>
        <HeaderZone iniciar={true}></HeaderZone>

        <Container className='create-account-home'  >
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h4" align="center">
              Create Account
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Gracias por crear una cuenta.
                </Typography>
                <Typography variant="subtitle1">
                  Ahora puedes disfrutar de los servicios de Trackia.
                  Para comenzar ingresa con tus credenciales <a href='/signin'>iniciar sesión</a>.
                  <br/><br/>
                  
                  En la <a href='/documentacion'>documentación</a> encontraras 
                  los pasos necesarios para una puesta en marcha rápida.
                  
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, setActiveStep)}
                
              </React.Fragment>
            )}
          </Paper>
          <Copyright />
        </Container>
      </React.Fragment>
    );
  }else{
    window.location.href = '/account/update';    
    return (<></>);
  }
  }

