import React, { useState } from 'react';
import { VariableView } from "./VariableView";

import { Barra } from './Barra';
import { Parametro } from './Parametro';
import { ParametroVar } from './ParametroVar';

let ident = "\u00A0\u00A0";
let end = ";";
let llaveInicio = "{";
let llaveTermino = "}";

function getParameter(journal) {
    let ret = Array();

    if(journal.request){
        for(let i=0; i<journal.request.length; i++){
            if(journal.request[i]){
                let className = journal.request[i].className;
            
                ret.push({
                    "className": className.substring(className.lastIndexOf(".")+1), 
                    "objectName": journal.request[i].name, 
                    "delimit": i===journal.request.length-1 ? "" : ", ",
                    "value": journal.request[i].value
                });
            }
        }
    }

    return ret;
}

function getImport(journal) {
    let ret = Array();

    if(journal.request){
        for(let i=0; i<journal.request.length; i++){
            if(journal.request[i]){
                let className = journal.request[i].className;

                if(!exist(ret, className)){
                    ret.push(className);
                }
            }
        }
    }

    if(journal.children){
        for(let i=0; i<journal.children.length; i++){
            let packageName = journal.children[i].packageName;
            if(!exist(ret, packageName)){
                ret.push(packageName);
            }

            packageName = journal.children[i].returnType;
            if(!exist(ret, packageName)){
                ret.push(packageName);
            }
        }
    }

    if(!exist(ret, journal.returnType)){
       ret.push(journal.returnType);
    }
    return ret;
}

function exist(list, element) {
    if(element === "void" || element === "boolean"){
        return true;
    }

    for(let i=0; i<list.length; i++){
        if(list[i] === element || element === undefined){
            return true;
        }
    }

    return false;
}

function getBody(data) {
    let journal = data.journal;
    let ret = Array();

    let durationBarStep = new Date(journal.transactionEnd).getTime() - new Date(journal.transactionStart).getTime();

    for(let i=0; i<journal.children.length; i++){
        let splitMethod = journal.children[i].method.split(":");
        if(splitMethod[0] === "HTTP"){
            //if(journal.children[i].children && journal.children[i].children.length > 0){
                if(true){
                let objectName = "restTemplate";
                let className = "className";
                let methodName = "exchange";
                let returnType = "ResponseEntity<T>";
                let objectInstance = "response";

                let aParams = Array();
                let url = journal.children.length > 0 && journal.children[0].url ?journal.children[0].url :"";
                aParams.push({"name": "url", "value": url,"delimit": ", "});
                aParams.push({"name": "HttpMethod", "value": splitMethod[1],"delimit": ", "});
                aParams.push({"name": "request", "value": journal.children.length > 0 && journal.children[0].request ? journal.children[0].request[0].value: "","delimit": ""});

                //journal.children[i].children[0].parent = journal;
                let durationChildren = new Date(journal.children[i].transactionEnd).getTime() - new Date(journal.children[i].transactionStart).getTime();
                let durationBar =   parseFloat(durationChildren * 100 / durationBarStep).toFixed(2);
                
                ret.push({
                    "objectName": objectName, 
                    "className": className, 
                    "methodName": methodName,
                    "returnType": returnType,
                    "objectInstance": objectInstance,
                    "comment": "HTTP Rest " + splitMethod[1] + "-> " + url.substring(0, 80),
                    "aParams": aParams,
                    "value": journal.children[i].response,
                    "journal": journal.children[i].children[0],
                    "onEditCode": data.onEditCode,
                    "duration": durationChildren,
                    "durationBar": durationBar,
                    "transactionStart": timeTransaction(journal.children[i].transactionStart),
                    "step": i+1
                });
            }

        }else{
            let objectName = splitMethod[0].substring(0, 1).toLowerCase() + splitMethod[0].substring(1);
            let className = splitMethod[0];
            let methodName = splitMethod[1];
            let returnType = journal.children[i].returnType 
                            ? journal.children[i].returnType.substring(journal.children[i].returnType.lastIndexOf(".")+1)
                            : "void";

            let objectInstance = returnType.substring(0, 1).toLowerCase() + returnType.substring(1);

            let aParams = Array();
            if(journal.children[i].request && journal.children[i].request.length > 0){
                for(let e=0; e<journal.children[i].request.length; e++){
                    if(journal.children[i].request[e]){
                        aParams.push({
                            "name": journal.children[i].request[e].name,
                            "value": journal.children[i].request[e].value,
                            "delimit": e===journal.children[i].request.length-1 ? "" : ", "
                        });
                    }
                }
            }
            journal.children[i].parent = journal;
            let durationChildren = new Date(journal.children[i].transactionEnd).getTime() - new Date(journal.children[i].transactionStart).getTime();
            let durationBar =   parseFloat(durationChildren * 100 / durationBarStep).toFixed(2);

            ret.push({
                "objectName": objectName, 
                "className": className, 
                "methodName": methodName,
                "returnType": returnType,
                "objectInstance": objectInstance,
                "comment": journal.children[i].description,
                "aParams": aParams,
                "value": journal.children[i].response,
                "journal": journal.children[i],
                "onEditCode": data.onEditCode,
                "duration": durationChildren,
                "durationBar": durationBar,
                "transactionStart": timeTransaction(journal.children[i].transactionStart),
                "step": i+1
            });
        }
    }

    

    return ret;
}

function timeTransaction(transactionStart){
    let date = new Date(transactionStart);

    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "." + date.getMilliseconds();
}

function getAutowired(journal) {
    let ret = Array();

    for(let i=0; i<journal.children.length; i++){
        let name = journal.children[i].method.split(":")[0];
        if(name === "HTTP"){
            if(!existAutowired(ret, "RestTemplate")){
                ret.push({"className": "RestTemplate", "objectName": "restTemplate"});
            }
            
        }else{
            if(!existAutowired(ret, name)){
                ret.push({"className": name, "objectName": name.substring(0, 1).toLowerCase() + name.substring(1)});
            }
        }
        
    }

    return ret;
}

function existAutowired(list, className){
    for(let i=0; i<list.length; i++){
        if(list[i].className === className){
            return true;
        }
    }

    return false;
}

function getReturnType(journal){
    
    let returnType = journal.returnType.substring(journal.returnType.lastIndexOf(".")+1)
    if(returnType === "ResponseEntity"){
        return "ResponseEntity<?>";
    }else{
        return returnType;
    }
}

export function CodeView(data, onEditCode) {
    function goBack(event, data){
        if(data.journal && data.journal.parent && data.onEditCode){
            data.onEditCode(event, {"journal": data.journal.parent.parent});
        }
    }


    if(data.journal && data.journal.transactionId){
        let journal = data.journal;
        let lineNumber = 1;

        let method = journal.method.split(":");
        let packageName = journal.packageName.substring(0,journal.packageName.lastIndexOf("."));
        let requestMethod;
        if(journal.requestMethod){
            requestMethod = journal.requestMethod.toUpperCase().substring(0, 1) + journal.requestMethod.toLowerCase().substring(1);
        }

        return (
            <>

            <div className="codeViewZone">
                <Barra journal={journal} 
                    goBack={goBack} 
                    maximizeView={data.maximizeView}
                    sizeCodeView={data.sizeCodeView}
                    onEditCode={data.onEditCode}
                    sizeView={data.sizeView}
                    displayTools={data.displayTools}
                    />

                <div className="codeViewZoneLeft">
                    <span className="line">
                        <a className="lineNumber">{lineNumber++}</a> 
                        <a className="import-text lineText">package </a>  {packageName}{end}
                    </span>
                    <span className="line"><a className="lineNumber">{lineNumber++}</a><br></br></span>
                    {getImport(journal).map(i => (
                        <span className="line" key={i}>
                            <a className="lineNumber">{lineNumber++}</a>
                            <a className="import-text"> import </a> {i};
                        </span>
                    ))} 

                    
                    <span className="line"><a className="lineNumber">{lineNumber++}</a> <br></br></span>
                    <span className="line">
                        <a className="lineNumber">{lineNumber++}</a> {journal.rootElement ?"@RestController": <br></br>} 
                    </span>
                    <span className="line">
                        <a className="lineNumber">{lineNumber++}</a> 
                        <a className="import-text">public class </a> 
                        <a className="public-class">{method[0]}</a> {llaveInicio}
                    </span>

                    {getAutowired(journal).map(a => (
                        <span className="line" key={a.objectName}>
                            <a className="lineNumber">{lineNumber++}</a> {ident}@Autowired 
                            <a className="public-class"> {a.className} </a> {a.objectName};
                        </span>
                    ))} 
                    <span className="line"><a className="lineNumber">{lineNumber++}</a><br></br></span>
                    
                    <span className="comment-doc line">
                        <a className="lineNumber">{lineNumber++}</a> 
                        {ident}{"/*(" + journal.duration + "ms) " + journal.description + "*/"}
                    </span>
                    {
                        journal.url
                        ?
                        <span className="comment-doc line">
                            <a className="lineNumber">{lineNumber++}</a> 
                            {ident}/*{journal.url}*/
                        </span>
                        :<></>
                     }


                    {
                        (requestMethod?
                            <span className="line">
                                <a className="lineNumber">{lineNumber++}</a> {ident}@{requestMethod}Mapping("{journal.restMethod}")
                            </span>
                            :""
                        )
                    }

                    <span className="line">
                        <a className="lineNumber">{lineNumber++}</a>{ident}
                            <a className="import-text">public </a> {getReturnType(journal)} <a className="public-class">{method[1]}</a>
                        (
                            {getParameter(journal).map(p => (
                                <ParametroVar key={p.objectName} p={p} OnObjectExplorer={data.OnObjectExplorer}/>
                            ))}
                        ) {llaveInicio}
                    </span>

                    {getBody(data).map(o => (
                        <div key={o.objectName + o.methodName + o.step}>
                            <span className="line comment-doc">
                                <a className="lineNumber">{lineNumber++}
                                <div className="durationBar" style={data.durationBar}> 
                                    <p className="durationBarLabel">{o.durationBar}%</p>
                                    <div className="durationBarStep" style={{width:o.durationBar + '%'}}></div> 
                                </div> </a> {ident}{ident}

                                /* Step {o.step}: {o.transactionStart} {o.comment} ({o.duration}ms)*/
                            </span>
                            <span className="line"><a className='lineNumber'>{lineNumber++} </a>{ident}{ident}
                            {
                                o.returnType == "void"?<></>
                                :
                                <>
                                    <a className="public-class">{o.returnType} </a> 
                                    <a className="varExp" onClick={() => data.OnObjectExplorer(o.value)}>{o.objectInstance}</a> = {' '}

                                </>
                            }
                            {o.objectName}.
                                <a className={`go${o && o.journal && o.journal.status ?o.journal.status:''}`} 
                                    onClick={() => data.onEditCode(this, {"journal": o.journal})}>{o.methodName}
                                </a>(
                                {o.aParams.map(ap => (
                                    <Parametro key={ap.name+ o.step} ap={ap} OnObjectExplorer={data.OnObjectExplorer}/>
                                ))} 

                            );</span>
                            <span className="line"><a className="lineNumber">{lineNumber++}</a><br></br></span>
                        </div>

                       
                    ))} 

                    <span className="line">
                        <a className="lineNumber">{lineNumber++}</a>
                        {ident}{ident}
                        <a className={`varExp return${journal.status}`}
                            onClick={() => data.OnObjectExplorer(journal.response)}>
                            {journal.status === "OK" ? "return": "throw new Exception()"}
                        </a>;
                    </span>
                    <span className="line"><a className="lineNumber">{lineNumber++}</a> {ident}{llaveTermino}</span>
                    <span className="line"><a className="lineNumber">{lineNumber++}</a> {llaveTermino}</span>
                    
                </div>

                <VariableView value={data.objectExplorer}/>
          </div>
          </>
        );
    }else{
        return (<></>);
    }
}
