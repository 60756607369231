import React from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';

import { properties } from '../properties';

export default class Service extends React.Component{
    constructor(){
        super();
        this.token = this.getToken();
	}

    getToken(){
        return localStorage.getItem("authorization");
    }

    getUser(){
        var decoded = jwt_decode(this.token);
        return decoded.user;
    }
    

    async connect(loginPayload){
        try{
            let response = await axios.post(properties.host + "/auth/signin", loginPayload);

            //get token from response
            this.token = response.data.accessToken;

            //set JWT token to local
            localStorage.setItem("authorization", this.token);
            return this.token;
        }catch(e){
            window.location.href = '/signin';    
            return undefined;
        }
    }

    async createAccount(account){
        let url = properties.host + "/auth/account";

        try{
            let response = await axios.post(url, account);
            this.token = response.data.accessToken;
            return response.data;
        }catch(e){
            return undefined;
        }
    }


    async userExist(email){
        let url = properties.host + "/auth/user/"+email;

        try{
            let response = await axios.get(url);
            return response.data;
        }catch(e){
            return undefined;
        }
    }

    disconnect(){
        this.token = undefined;
        localStorage.removeItem('authorization');
    }


    async reconnect(){
        if(this.token){
            try{
                let response = await axios(properties.host + "/auth/reconnect", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' +this.token
                    }
                });
                this.token = response.data.accessToken;

                //set JWT token to local
                localStorage.setItem("authorization", this.token);
            }catch(e){
                window.location.href = '/signin';    
            }
        }else{
            window.location.href = '/signin';
        }
    }



    async findByTransactionId(journalInput){
        let url = properties.host +"/journal/" +journalInput;

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
        return response;
    }

    async findExampleByTransactionId(journalInput){
        let url = properties.host +"/auth/example/" +journalInput;

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
        return response;
    }

    async getArtifacts(){
        let url = properties.host +"/auth/artifact/list";

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
        return response.data;

    }

    async registerArtifact(body){
        let url = properties.host +"/auth/artifact/register";
        
        return await this.post(url, {
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        }, body);
    }

    async getAccount(){
        let url = properties.host +"/useraccount";

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
        return response.data;

    }

    async updateArtifact(body){
        let url = properties.host +"/auth/artifact/update";
        
        return await this.post(url, {
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        }, body);
    }

    async deleteArtifact(id){
        let url = properties.host +"/auth/artifact/delete/" + id;
        
        await this.exec(url, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
    }

    async updateAccount(account){
        let url = properties.host + "/auth/account";

        return await this.put(url, {
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        }, account);

    }

    async getUsersAccount(){
        let url = properties.host +"/usersAccount";

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });
        return response ? response.data: null;
    }
    
    async put(url, params, body){
        try{
            const res = await axios({
                method: 'put',
                url: url,
                data: body,
                headers:{
                    'Authorization': 'Bearer ' +this.token
                }
            });


            
        }catch(e){
            if(e && e.response && e.response.status == 401){
                try{
                    await this.reconnect();
                    params["headers"] = {'Authorization': 'Bearer ' +this.token};

                    const res = await axios({
                        method: 'put',
                        url: url,
                        data: body,
                        headers:{
                            'Authorization': 'Bearer ' +this.token
                        }
                    });

                }catch(e){
                    window.location.href = '/signin';
                }
            }
         }
    }

    async post(url, params, body){
        try{
            return await axios.post(url, body, params);
            
        }catch(e){
            if(e && e.response && e.response.status == 401){
                try{
                    await this.reconnect();
                    params["headers"] = {'Authorization': 'Bearer ' +this.token};

                    return await axios.post(url, body, params);
                }catch(e){
                    window.location.href = '/signin';
                }
            }
         }
    }


    async exec(url, params){
        try{
            return await axios(url, params);
            
        }catch(e){
            if(e && e.response && e.response.status == 401){
                try{
                    await this.reconnect();
                    params["headers"] = {'Authorization': 'Bearer ' +this.token};

                    return await axios(url, params);
                }catch(e){
                    window.location.href = '/signin';
                }
            }
         }
    }   

    async getSummary(transactionStart, transactionEnd){
        let url = properties.host +"/journal/summary"
        + "?transactionStart=" + this.getDateString(transactionStart)
        + "&transactionEnd=" + this.getDateString(transactionEnd);

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }
        });

        return response.data;
    }

    getDateString(date){
        //yyyy-MM-dd
        let d = date.toDate();
        return d.getFullYear() + "-" + ((d.getMonth()*1)+1) + "-" + d.getDate();
    }

    async journalSearch(artifactid, status, transactionStart, transactionEnd, limit, skip, sort){

        let url = properties.host +"/journal/search/"+artifactid+"/"+status.toUpperCase()
        + "?limit=" + limit 
        + "&skip=" + skip
        + "&transactionStart=" + this.getDateString(transactionStart)
        + "&transactionEnd=" + this.getDateString(transactionEnd)
        + "&sortField=" + sort.field
        + "&sortOrder=" + sort.sort;

        let response = await this.exec(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' +this.token
            }

        });

        return response.data;
    }
}


