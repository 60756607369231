import React from 'react';
import { ObjectInspector, TableInspector, Inspector } from 'react-inspector';
import { chromeDark } from 'react-inspector'

export function VariableView(data) {
    let theme={...chromeDark, ...(
        { 
            BASE_FONT_FAMILY: 'DejaVu Sans Mono',   //no funciona
            BASE_FONT_SIZE: '40px',                 //no funciona
            BASE_LINE_HEIGHT: '40px',               //no funciona
            BASE_BACKGROUND_COLOR: 'var(--debug-background-color)',
            BASE_COLOR: 'var(--debug-import-doc)',
            OBJECT_NAME_COLOR: 'var(--debug-public-class)',
            OBJECT_VALUE_NULL_COLOR: 'var(--debug-import-doc)',
            OBJECT_VALUE_NULL_COLOR: 'var(--debug-import-text)',
            TREENODE_PADDING_LEFT: 15,
            OBJECT_VALUE_STRING_COLOR: 'var(--debug-letters-color)',
            ARROW_COLOR: 'var(--debug-letters-color)',
            TREENODE_FONT_FAMILY: 'DejaVu Sans Mono',
            TREENODE_FONT_SIZE: '15px'
        }
    )}

    return(
        <div className="codeViewZoneRight">
            <div className="ObjectInspector">
                <ObjectInspector theme={theme} expandLevel="1" data={data.value} />
            </div>
        </div>
    );

}