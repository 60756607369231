import React from 'react';
import './documentation.css';
import { HeaderZone } from '../HeaderZone';
import { MenuDocumentacion } from './MenuDocumentacion';

export function Install(){
    return (
        <>
<div className="contenedor-documentacion">
          <HeaderZone iniciar={true}/>
            <div className="cuerpo-documentacion">
                <div className="documentacionTrackia">
                    <MenuDocumentacion />
                    <div className="doc-contenido">

                        <h1 id="instalacion">Instalación</h1>

                        <h2>Desde Linux</h2>
                        <h3 style={{fontSize: "22px"}}>Download</h3>
                        <p>Para bajar la librería ejecute el siguiente comando.</p>

                        <div className="pre">
                            <br/>
                            <p>
                                <a className="import-text">curl -L -O https://www.trackia.app/download/lib/trackia-1.1.6.zip</a>
                            </p>
                        </div>


                        <h3 style={{fontSize: "22px"}}>Install</h3>
                        <p>Luego descomprima el archivo en el directorio .m2 para ser alcanzado como dependencia maven.</p>

                        <div className="pre">
                            <br/>
                            <p>
                                <a className="import-text">unzip trackia-1.1.6.zip -d $HOME/repository/</a>
                            </p>
                        </div>


                        <br/>
                        <h2>Desde Windows</h2>

                        <h3 style={{fontSize: "22px"}}>Download</h3>
                        <p>Para bajar la librería ejecute el siguiente comando.</p>

                        <div className="pre">
                            <br/>
                            <p>
                                <a className="import-text">curl -L -O https://www.trackia.app/download/lib/trackia-1.1.6.zip</a>
                            </p>
                        </div>

                        <h3 style={{fontSize: "22px"}}>Install</h3>
                        <p>Luego descomprima el archivo en el directorio <b>.m2</b> para ser alcanzado como dependencia maven.</p>

                        <div className="pre">
                            <br/>
                            <p>
                                <a className="import-text">winzip trackia-1.1.6.zip</a>
                            </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
     </>);
}