import { Box, Button } from '@mui/material';
import React from 'react';
import Service from '../../helpers/Service';


async function onE2E(event, element) {
    let srv = new Service();
    let user = srv.getUser();

    let localModel = localStorage.getItem(user.id + "_model");
    if(!localModel){
        localModel = [];
        window.localStorage.setItem(user.id + "_model", JSON.stringify(localModel));
    }else{
        try{
          localModel = JSON.parse(localModel);
        }catch(e){
          localModel = [];
        }
    }

    var i=0;
    var exist = false;
    while(i<localModel.length && !exist){
        if(localModel[i].transactionId == element.row.transactionId){
            exist = true;
        }
        i++;
    }

    if(!exist){
        let response = await srv.findByTransactionId(element.row.transactionId);
        if(response != undefined && response != null){
            let obj = response.data;
            localModel[localModel.length] = obj;

            window.localStorage.setItem(user.id + "_selected", element.row.transactionId);
            window.localStorage.setItem(user.id + "_model", JSON.stringify(localModel));
            window.location.href = '/E2E';
        }

    }else{
        window.localStorage.setItem(user.id + "_selected", element.row.transactionId);
        window.location.href = '/E2E';
    }
}

export function RenderAction(params) {
    return(<>
    
        <Box sx={{ display: 'flex', justifyContent: 'flex-begin'}}>
            <Button 
                style={{'backgroundColor': "var(--status-" +params.row.status.toLowerCase() + ")"}} onClick={(event) => {onE2E(event, params)}} variant="contained">E2E</Button>
        </Box>
    </>);

}