import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AccountContext from "./AccountContext";
import { Box, Button, FormControl } from '@mui/material';
import Service from '../../../helpers/Service';

export default function UserForm({setActiveStep}) {
  const context = React.useContext(AccountContext);
  const service = new Service();

  const [classText, setClassText] = React.useState([]);
  const [firstName, setFirstName] = React.useState(context.firstName);
  const [lastName, setLastName] = React.useState(context.lastName);
  const [phone, setPhone] = React.useState(context.phone);
  const [email, setEmail] = React.useState(context.email);
  const [password, setPassword] = React.useState(context.password);
  const [repeat, setRepeat] = React.useState(context.repeat);

  const styles = theme => ({
    '@keyframes blinker': {
        from: {opacity: 1},
        to: {opacity: 0}
    },
    headerGT: {
        color: 'white',
        animation: ['blinker', '1s', 'linear', 'infinite'],
        '-webkit-animation': ['blinker', '1s', 'linear', 'infinite'],
        '-moz-animation': ['blinker', '1s', 'linear', 'infinite'],
    }
})

  const setValueForm = (field, val) => {
      context[field] = val;

      let localClassText = classText ;
      localClassText[field] = "";
      setClassText(localClassText);
  }

  const handleNext = async () => {
    let valid = await isValid();
    if(valid){
      setActiveStep(1);
    }
  }

  const handleBack = () => {
    
  }

  const valid = ["firstName", "lastName", "phone", "email", "password", "repeat"];
  const isValid = async () => {
    let isValid = true;

    let classText = [];
    setClassText(classText);
    for(let i=0; i<valid.length; i++){
      let name = valid[i];
      if(context[name] == "" || context[name] == undefined){
        classText[name] = "blink";
        isValid = false;
      }else{
        classText[name] = "";
      }
    }

    if(context.password.length < 5){
      isValid = false;
      classText["password"] = "blink";
    }

    if(context.password != context.repeat){
      classText["repeat"] = "blink";
      isValid = false;
    }

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!(email+"").match(validRegex)) {
      classText["email"] = "blink";
      isValid = false;
    }

    if(isValid){
      isValid = ! await service.userExist(email);
      if(!isValid){
        classText["email"] = "blink";
      }
    }

    
    setClassText(classText);
    return isValid;
  }



  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        User Root
      </Typography>
      <FormControl autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            className={classText["firstName"]}
            value={firstName}
            onChange={event => {setValueForm("firstName", event.target.value); setFirstName(event.target.value)}} 
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            className={classText["lastName"]}
            value={lastName}
            onChange={event => {setValueForm("lastName", event.target.value); setLastName(event.target.value)}} 
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Phone"
            name="phone"
            label="Phone"
            className={classText["phone"]}
            value={phone}
            onChange={event => {setValueForm("phone", event.target.value); setPhone(event.target.value)}} 
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Email"
            name="email"
            label="Email"
            className={classText["email"]}
            value={email}
            onChange={event => {setValueForm("email", event.target.value); setEmail(event.target.value)}} 
            fullWidth
            autoComplete="new-password"
            variant="standard"
          />
        </Grid>
        

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Password"
            type="password"
            name="password"
            label="Password"
            className={classText["password"]}
            value={password}
            onChange={event => {setValueForm("password", event.target.value); setPassword(event.target.value)}} 
            autoComplete="new-password"
            fullWidth
             variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="Repeat"
            type="password"
            name="repeat"
            label="Repeat"
            className={classText["repeat"]}
            value={repeat}
            onChange={event => {setValueForm("repeat", event.target.value); setRepeat(event.target.value)}} 
            fullWidth
            variant="standard"
          />
        </Grid>
        
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={{"display": "none"}} onClick={() => {handleBack()}}
                    sx={{ mt: 3, ml: 1 }}>
                      Back
          </Button>

          <Button
                    variant="contained"
                    onClick={() => {handleNext()}}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    Next
          </Button>
      </Box>

 
      </FormControl>
    </React.Fragment>
  );
}