import React from 'react';
import { TbArrowNarrowLeft, TbArrowsMaximize, TbArrowsMinimize, TbMinus } from 'react-icons/tb';
import { GoTools } from "react-icons/go";

import { AiFillCloseCircle, AiOutlineCloseCircle } from 'react-icons/ai';

export function Barra(data) {
    if(data.journal && data.journal.transactionId){
        let journal = data.journal;

        return(
            <div className="barra">
            <div className="barraLeft">
                {(
                    journal.parent
                    ? <div className="botonBarra radius-2cqb"
                        onClick={() => data.goBack(this,{"journal": {"parent": journal}, "onEditCode": data.onEditCode})}
                    ><TbArrowNarrowLeft className="boton" size={22}  ></TbArrowNarrowLeft>
                    </div>

                    :<></>
                )}

                <div className="botonBarra radius-2cqb"
                    onClick={() => data.displayTools()}
                    >
                    <GoTools className="boton2" size={10}  ></GoTools>
                </div>
            </div>





        <div className="barraRight">
                <div className="botonBarra radius-2cqb"
                        onClick={() => data.maximizeView()}
                >
                    {(
                        data.sizeCodeView.display === "none" 
                        ? <></>
                        : <AiFillCloseCircle className="boton" size={22}  ></AiFillCloseCircle>
                    )}
                </div>




            </div>
        </div>
        );
    }else{
        return (<></>);
        
    }

}